import { combineReducers } from '@reduxjs/toolkit'
import auth from "./auth/reducer";
import venue from "./venue/reducer";
import staff from "./staff/reducer";
import parking from "./parking/reducer";
import config from "./config/reducer";
import notification from "./notification/reducer";
import common from "./common/reducer";

const rootReducer = combineReducers({
    auth,
    venue,
    staff,
    parking,
    config,
    notification,
    common
})

export default rootReducer
