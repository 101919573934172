import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router";
import {useSelector} from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RequestCard from "../../components/request/RequestCard";
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';
import RequestDetailCard from "../../components/request/RequestDetailCard";
import ParkingCard from "../../components/parking/ParkingCard";

const RequestListCard = () => {
    const user = useSelector((state) => state.auth);
    const allRequests = useSelector((state) => state.parking.request);
    const allParking = useSelector((state) => state.parking.parking);
    const allStaff = useSelector((state) => state.staff.admins);
    const allVenue = useSelector((state) => state.venue.venues);
    const [parking, setParking] = useState([]);
    const [requests, setRequests] = useState([]);
    const [showParkingDetail, setShowParkingDetail] = useState(false);
    const [currentParking, setCurrentParking] = useState("");
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1700,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    useEffect(() => {
        let _request = [];
        let venue;
        let valet;
        let parking;
        allRequests.forEach((p) => {
            if (user.type === 'Manager' && user.venues.includes(p.venue) || user.type !== 'Manager') {
                venue = allVenue.find((v) => v._id === p.venue);
                valet = allStaff.find((s) => s._id === p.valet);
                parking = allParking.find((s) => s.ticket === p.ticket);

                if (parking && venue && valet) {
                    let request = { ...p };
                    request.venueDetails = venue;
                    request.valetDetails = valet;
                    request.parkingDetails = parking;
                    _request.push(request);
                }
            }
        });
        setRequests(_request);
        console.log(_request);
    }, [allRequests, allParking, allStaff, allVenue])


    return (
        <>
            {allVenue.filter((v) => v.status === "Active" && !v.deleted && (user.type === 'Manager' && user.venues.includes(v._id) || user.type !== 'Manager')).map(venue => {
                return <>
                    <div className="mb-5 mt-5">
                        <h3 className="text-center mb-5">{venue.name}</h3>
                        <h5>Requested</h5>
                        <Slider {...settings} className="cards mb-5">
                            {requests.filter((p) => p.status === "Requested" && p.venue === venue._id).map(p => (
                                <RequestCard parking={p} onClick={() => {
                                    setCurrentParking(p.ticket);
                                    setShowParkingDetail(true);
                                }} />
                            ))}
                        </Slider>

                        <h5>On-Going</h5>
                        <Slider {...settings} className="cards mb-5">
                            {requests.filter((p) => p.status !== "Requested" && p.status !== "Delivered" && p.venue === venue._id).map(p => (
                                <RequestCard parking={p} onClick={() => {
                                    setCurrentParking(p.ticket);
                                    setShowParkingDetail(true);
                                }} />
                            ))}
                        </Slider>

                    </div>


                </>;
            })}

            <br />
            <Modal
                show={showParkingDetail}
                backdrop="static"
                onHide={() => {
                    setShowParkingDetail(false);
                }}
                keyboard={true}
            >
                {currentParking && <RequestDetailCard ticket={currentParking} onClose={() => {
                    setShowParkingDetail(false);
                    setCurrentParking(null);
                }} />}
            </Modal>
        </>
    );
}

export default RequestListCard;
