import {createSlice} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';


const initialState = {venueTypes: [], venues: [], slots: []}

const venueSlice = createSlice({
    name: 'venue',
    initialState,
    reducers: {
        venueTypeAddSuccess(state, action) {
            if (action.payload.data) {
                state.venueTypes.push(action.payload.data);
                toast.info(action.payload.message, {});
            }
        },
        venueTypeAddFailed(state, action) {
            toast.warning(action.payload.message, {});
        },
        venueTypeLoaded(state, action) {
            state.venueTypes.push(...action.payload.data)
        },
        venueTypeEditSuccess(state, action) {
            if (action.payload.data) {
                let index = state.venueTypes.findIndex(venueType => venueType._id === action.payload.data._id);
                if (index >= 0) {
                    state.venueTypes[index] = action.payload.data;
                    toast.info(action.payload.message, {});
                }
            }
        },
        venueTypeEditFailed(state, action) {
            toast.warning(action.payload.message, {});
        },
        venueTypeDeleteSuccess(state, action) {
            if (action.payload.data) {
                state.venueTypes = state.venueTypes.filter(venueType => venueType._id !== action.payload.data._id);
                toast.info(action.payload.message, {});
            }
        },
        venueTypeDeleteFailed(state, action) {
            toast.warning(action.payload.message, {});
        },
        venueAddSuccess(state, action) {
            if (action.payload.data) {
                state.venues.push(action.payload.data);
                toast.info(action.payload.message, {});
            }
        },
        venueAddFailed(state, action) {
            toast.warning(action.payload.message, {});
        },
        venueLoaded(state, action) {
            state.venues.push(...action.payload.data)
        },
        slotAddSuccess(state, action) {
            if (action.payload.data) {
                state.slots.push(action.payload.data);
                toast.info(action.payload.message, {});
            }
        },
        slotAddFailed(state, action) {
            toast.warning(action.payload.message, {});
        },
        slotLoaded(state, action) {
            state.slots.push(...action.payload.data)
        },
        venueEditSuccess(state, action) {
            if (action.payload.data) {
                let index = state.venues.findIndex(venue => venue._id === action.payload.data._id);
                if (index >= 0) {
                    state.venues[index] = action.payload.data;
                    toast.info(action.payload.message, {});
                }
            }
        },
        venueEditFailed(state, action) {
            toast.warning(action.payload.message, {});
        },
        venueDeleteSuccess(state, action) {
            if (action.payload.data) {
                state.venues = state.venues.filter(venue => venue._id !== action.payload.data._id);
                toast.info(action.payload.message, {});
            }
        },
        slotEditSuccess(state, action) {
            if (action.payload.data) {
                let index = state.slots.findIndex(slot => slot._id === action.payload.data._id);
                if (index >= 0) {
                    state.slots[index] = action.payload.data;
                    toast.info(action.payload.message, {});
                }
            }
        },
        slotDeleteSuccess(state, action) {
            if (action.payload.data) {
                state.slots = state.slots.filter(slot => slot._id !== action.payload.data._id);
                toast.info(action.payload.message, {});
            }
        },
        venueDeleteFailed(state, action) {
            toast.warning(action.payload.message, {});
        },
    },
})

export const {
    venueTypeAddSuccess,
    venueTypeAddFailed,
    venueTypeLoaded,
    venueTypeEditSuccess,
    venueTypeEditFailed,
    venueTypeDeleteSuccess,
    venueTypeDeleteFailed,
    venueAddSuccess,
    venueAddFailed,
    venueLoaded,
    slotAddSuccess,
    slotAddFailed,
    slotLoaded,
    venueEditSuccess,
    venueEditFailed,
    venueDeleteSuccess,
    slotDeleteSuccess,
    venueDeleteFailed,
    slotEditSuccess
} = venueSlice.actions;
export default venueSlice.reducer;
