import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {useSelector} from "react-redux";
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net';
import {useLocation, useNavigate, useParams} from "react-router";
import store from "../../store";
import moment from "moment";
import Select from 'react-select';

const ManageRequests = () => {
    const user = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const allRequests = useSelector((state) => state.parking.request);
    const allStaff = useSelector((state) => state.staff.admins);
    const allVenue = useSelector((state) => state.venue.venues);
    const [requests, setRequests] = useState([]);


    useEffect(() => {
        try {
            $('#table').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        $('#table').DataTable({
            data: requests,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        if (row[2] === 'Delivered') {
                            return `<p class="badge bg-success">${data}</p>`;
                        } else {
                            return `<p class="badge bg-primary">${data}</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 0
                },
                {

                    "render": function (data, type, row) {
                        if (data === 'Delivered') {
                            return `<p class="badge bg-success">${data}</p>`;
                        } else {
                            return `<p class="badge bg-primary">${data}</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 2
                },
                {

                    "render": function (data, type, row) {
                        if (data === 'NOT ASSIGNED') {
                            return `<p class="badge bg-danger">${data}</p>`;
                        } else {
                            return `${data}`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 4
                },
                {
                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary"><i class="fa fa-pen me-2"></i>Details</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            console.log('CLICKED');
                            navigate(`${location.pathname}/${cellData}`);
                        })
                    },
                    "targets": 5
                },
            ],
            order: []
        });
    }, [requests]);


    useEffect(() => {
        let _request = [];
        let venue;
        let valet;
        allRequests.forEach((p) => {
            if (user.type === 'Manager' && user.venues.includes(p.venue) || user.type !== 'Manager') {
                venue = allVenue.find((v) => v._id === p.venue);
                valet = allStaff.find((s) => s._id === p.valet);
                _request.push([p.ticket, moment(p.timings.requested || "").format('MMMM Do YYYY, h:mm:ss a'), p.status, venue.name || "", valet != null ? valet.name : "NOT ASSIGNED", p._id]);
            }
        });
        setRequests(_request);
    }, [allRequests])


    return (
        <div>
            <div class="card mt-3">
                <div className="card-header">
                    <h5 className="card-title">Request Management</h5>
                </div>
                <div class="card-body">
                    <div className='table-responsive'>
                        <table
                            id="table"
                            className='table datatable'
                        >
                            <thead>
                            <tr>
                                <th>Ticket</th>
                                <th>Request Time</th>
                                <th>Status</th>
                                <th>Venue</th>
                                <th>Valet</th>
                                <th>Details</th>
                            </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default ManageRequests;
