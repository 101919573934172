import { useSelector } from "react-redux";
import Login from "../pages/authentication/Login";
import Base from "../pages/Base";
import React, { Component }  from 'react';
import { useLocation } from 'react-router-dom'
import Dashboard from "../pages/Dashboard";



const AuthMiddleware = ({ children }) => {
    const user = useSelector((state) => state.auth);
    let token = localStorage.getItem('token');
    const location = useLocation();
    let pathAllowed = false;

    for (let path of user.allowedPaths) {
        if (location.pathname.includes(path)) {
            pathAllowed = true;
        }
    }


    return (
        user.token != null || token !== null ? <Base>
            {pathAllowed ? children : <Dashboard />}
        </Base> :  <Login />
    )
}

export default AuthMiddleware;
