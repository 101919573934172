import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {useSelector} from "react-redux";
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net';
import {useLocation, useNavigate, useParams} from "react-router";
import store from "../../store";
import {changeStaffStatus, deleteStaff} from "../../store/staff/action";
import moment from "moment";

const ManageAdminStaff = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state) => state.auth);
    const allStaff = useSelector((state) => state.staff.admins);
    const [staff, setStaff] = useState([]);



    useEffect(() => {
        try {
            $('#table').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        $('#table').DataTable({
            data: staff,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        if (data) {
                            return `<div class="figure"><img src="${data}" style="width: 60px;height: 60px; border-radius: 5px"></div>`;
                        }
                        return '<div></div>';
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 0
                },
                {

                    "render": function (data, type, row) {
                        if (data === 'Active') {
                            return `<p class="badge bg-success">Active</p>`;
                        } else {
                            return `<p class="badge bg-danger">Deactive</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 3
                },
                {

                    "render": function (data, type, row) {
                        if (row[3] === 'Active') {
                            return `<button class="btn btn-danger"><i class="fa fa-times-circle me-2"></i>Deactivate</button>`;
                        } else {
                            return `<button class="btn btn-success"><i class="fa fa-check-circle me-2"></i>Activate</button>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            store.dispatch(changeStaffStatus(cellData));
                        })
                    },
                    "targets": 6
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-danger"><i class="fa fa-trash me-2"></i>Delete</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            store.dispatch(deleteStaff(cellData));
                        })
                    },
                    "targets": 7
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary"><i class="fa fa-pen me-2"></i>Edit</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            console.log('CLICKED');
                            navigate(`${location.pathname}/edit/${cellData}`);
                        })
                    },
                    "targets": 5
                },
            ],
            order: []
        });
    }, [staff, params]);



    useEffect(() => {
        let _staff = [];
        allStaff.forEach((staff) => {
            if (!staff.deleted) {
                if (user.type === 'Manager' && staff.type === "Valet" && staff.venues.length > 0 && user.venues.includes(staff.venues[0])) {
                    _staff.push([staff.profilePic || "", staff.name, staff.email, staff.status, moment(staff.createdAt || "").format('MMMM Do YYYY, h:mm:ss a'), staff._id, staff._id, staff._id])
                } else if (user.type !== 'Manager') {
                    if (params.type === "manager" && staff.type === "Manager") {
                        _staff.push([staff.profilePic || "", staff.name, staff.email, staff.status, moment(staff.createdAt || "").format('MMMM Do YYYY, h:mm:ss a'), staff._id, staff._id, staff._id])
                    } else if (params.type === "admin" && staff.type === "Admin") {
                        _staff.push([staff.profilePic || "", staff.name, staff.email, staff.status, moment(staff.createdAt || "").format('MMMM Do YYYY, h:mm:ss a'), staff._id, staff._id, staff._id])
                    } else if (params.type === "valet" && staff.type === "Valet") {
                        _staff.push([staff.profilePic || "", staff.name, staff.email, staff.status, moment(staff.createdAt || "").format('MMMM Do YYYY, h:mm:ss a'), staff._id, staff._id, staff._id])
                    }
                }

            }
        });
        setStaff(_staff);
    }, [allStaff, params])

    return (
        <div>
            <div class="card mt-3">
                <div className="card-header">
                    <h5 className="card-title">Manage {params.type}</h5>
                </div>
                <div class="card-body">
                    <div className='table-responsive'>
                        <table
                            id="table"
                            className='table datatable'
                        >
                            <thead>
                            <tr>
                                <th>Profile Pic</th>
                                <th>Name</th>
                                <th>email</th>
                                <th>Status</th>
                                <th>Date</th>
                                <th>Details</th>
                                <th>Change Status</th>
                                <th>Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default ManageAdminStaff;
