import React, {useEffect, useState} from 'react';
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import 'moment-countdown';
import {useSelector} from "react-redux";
import Select from "react-select";
import store from "../../store";
import {assignValetToRequest} from "../../store/parking/action";


const RequestDetailCard = ({ticket, onClose}) => {
    const [checkInTimer, setCheckInTimer] = useState('');
    const allStaff = useSelector((state) => state.staff.admins);
    const [staff, setStaff] = useState([]);
    const [valet, setValet] = useState(null);
    const allRequests = useSelector((state) => state.parking.request);
    const allParking = useSelector((state) => state.parking.parking);
    const allVenue = useSelector((state) => state.venue.venues);
    const [parking, setParking] = useState(null);

    useEffect(() => {
        if (ticket) {
            let _parking = allParking.find((p) => p.ticket === ticket);
            let _request = allRequests.find((p) => p.ticket === ticket);
            let venue = allVenue.find((v) => v._id === _parking.venue.id);
            let valet = allStaff.find((s) => s._id === _parking.valet);
            setParking({
                ..._request,
                parkingDetails: _parking,
                venueDetails: venue,
                valetDetails: valet,
            })
            let interval = setInterval(function(){
                let duration = moment.duration(moment.now() - moment(_request['timings']['requested']), 'milliseconds');
                setCheckInTimer(duration.days() + ":" + duration.hours() + ":" + duration.minutes() + ":" + duration.seconds());
            }, 1000);
            setValet(_parking.valet);

            return () => clearInterval(interval);
        }

    }, [allParking, allRequests, allStaff, allVenue])

    useEffect(() => {
        if (parking && parking.venue != null) {
            let _staff = [];
            for (let s of allStaff) {
                if (s.type === 'Valet' && s.venues.includes(parking.venue)) {
                    _staff.push({value: s._id, label: `${s.name} (${s.available ? "AVAILABLE" : "NOT AVAILABLE"})`},)
                }
            }
            setStaff(_staff);
        }
    }, [allStaff, parking]);

    return (
        <>
            {parking !== null && <div className="parking-detail-card row">
                <div className="col-12 d-flex justify-content-end mb-3">
                    <img src='/assets/img/close-popup.png' style={{ width: "20px", cursor: "pointer" }} onClick={onClose} />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 p-0 m-0">
                    <div className="car-image p-0 m-0" style={{backgroundImage: `url('${parking['parkingDetails']['car']['image'] !== '' ? parking['parkingDetails']['car']['image'] : ''}')`, backgroundSize: 'cover'}} />
                    <br />
                    <div className="card parking-details row p-0 m-0">
                        <div className="col-12 d-flex justify-content-between detail-item">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="tag"/></div>
                                <p className="item-title">Ticket</p>
                            </div>
                            <div className="item-value">#{parking['ticket']}</div>
                        </div>
                        <div className="col-12 d-flex justify-content-between detail-item">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="bar-chart"/></div>
                                <p className="item-title">Level</p>
                            </div>
                            <div className="item-value">{parking['parkingDetails']['venue']['slots'] !== undefined && parking['parkingDetails']['venue']['slots']['parking']['level']['title']}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-between detail-item">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="map-pin"/></div>
                                <p className="item-title">Spot</p>
                            </div>
                            <div className="item-value">{parking['parkingDetails']['venue']['slots'] !== undefined && parking['parkingDetails']['venue']['slots']['parking']['slot']['title']}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-between detail-item">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="key"/></div>
                                <p className="item-title">Key Slot</p>
                            </div>
                            <div className="item-value">{parking['parkingDetails']['venue']['slots'] !== undefined && parking['parkingDetails']['venue']['slots']['key']['title']}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-between detail-item">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="dollar-sign"/></div>
                                <p className="item-title">Payment Mode</p>
                            </div>
                            <div className="item-value">{parking['payment']['method']}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-between detail-item">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="command"/></div>
                                <p className="item-title">Parking Type</p>
                            </div>
                            <div className="item-value">{parking['parkingDetails']['price']['rate']}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-between detail-item">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="dollar-sign"/></div>
                                <p className="item-title">Payment Status</p>
                            </div>
                            <div className="item-value">{parking['payment']['status']}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-between detail-item">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="credit-card"/></div>
                                <p className="item-title">Amount</p>
                            </div>
                            <div className="item-value">${parking['parkingDetails']['price']['amount']}</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="d-flex justify-content-end">
                        <div className="timing">
                            {parking['status'] === "Delivered" ? <p>{moment(parking['timings']['delivered']).diff(moment(parking['timings']['requested']), 'hours')}:{moment(parking['timings']['delivered']).diff(moment(parking['timings']['requested']), 'minutes')}:{moment(parking['timings']['delivered']).diff(moment(parking['timings']['requested']), 'seconds') % 60}</p> : <p>{checkInTimer}</p>}
                        </div>
                    </div>
                    <div className="card parking-details row p-0 m-0">
                        <div className="col-12 d-flex justify-content-start detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="user"/></div>
                            </div>
                            <div className="item-value user-details">{parking['parkingDetails']['guest']['firstName']} {parking['parkingDetails']['guest']['lastName']}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-start detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="hash"/></div>
                            </div>
                            <div className="item-value user-details">{parking['parkingDetails']['car']['licensePlate']}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-start detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="mail"/></div>
                            </div>
                            <div className="item-value user-details w-100">{parking['parkingDetails']['guest']['email']}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-start detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="phone"/></div>
                            </div>
                            <div className="item-value user-details">{parking['parkingDetails']['guest']['phoneNumber']}</div>
                        </div>

                    </div>
                    <br /><br />
                    <div className="card parking-details row p-0 m-0">
                        <div className="col-12 d-flex justify-content-between detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <p className="item-title">Valet Name</p>
                            </div>
                            <div className="item-value">{parking['valetDetails']['name']}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-between detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <p className="item-title">Valet Email</p>
                            </div>
                            <div className="item-value">{parking['valetDetails']['email']}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-between detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <p className="item-title">Valet No.</p>
                            </div>
                            <div className="item-value">{parking['valetDetails']['phoneNumber']}</div>
                        </div>
                    </div>
                    <br /><br />
                    <div className="card parking-details row p-0 m-0">
                        <div className="col-12 d-flex justify-content-between detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <p className="item-title">Requested Time</p>
                            </div>
                            <div className="item-value">{parking['timings']['requested'] && moment(parking['timings']['requested']).format('DD/MM/YY hh:mm:ss')}</div>
                        </div>
                        <div className="col-12 d-flex justify-content-between detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <p className="item-title">Job Accepted Time</p>
                            </div>
                            <div className="item-value">{parking['timings']['accepted'] && moment(parking['timings']['accepted']).format('DD/MM/YY hh:mm:ss')}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-between detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <p className="item-title">Key Collected Time</p>
                            </div>
                            <div className="item-value">{parking['timings']['keyCollected'] && moment(parking['timings']['keyCollected']).format('DD/MM/YY hh:mm:ss')}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-between detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <p className="item-title">Car Collected Time</p>
                            </div>
                            <div className="item-value">{parking['timings']['carCollected'] && moment(parking['timings']['carCollected']).format('DD/MM/YY hh:mm:ss')}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-between detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <p className="item-title">Delivered Time</p>
                            </div>
                            <div className="item-value">{parking['timings']['delivered'] && moment(parking['timings']['delivered']).format('DD/MM/YY hh:mm:ss')}</div>
                        </div>
                    </div>
                    <br />
                    <br />
                    {parking.status !== 'Delivered' && <div className="card parking-details row p-0 m-0">
                        <h4>Assign Valet</h4>
                        <br />
                       <Select
                            name="staff"
                            className="select w-100"
                            options={staff}
                            onChange={(e) => {
                                store.dispatch(assignValetToRequest(parking._id, e.value));
                            }}
                        />
                    </div>}
                </div>
            </div>}</>
    );
}

export default RequestDetailCard;
