import { configureStore } from '@reduxjs/toolkit';
import rootReducer from "./reducers";
import thunk from "redux-thunk";
import logger from 'redux-logger';


const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk, logger],
})

export default store;
