import React, {useEffect, useState} from 'react';
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import 'moment-countdown';


const ParkingCard = ({parking, onClick}) => {
    const [checkInTimer, setCheckInTimer] = useState('');

    useEffect(() => {
        let interval = setInterval(function(){
            let duration = moment.duration(moment.now() - moment(parking['timings']['arrival']), 'milliseconds');
            setCheckInTimer(duration.days() + ":" + duration.hours() + ":" + duration.minutes() + ":" + duration.seconds());
        }, 1000);

        return () => clearInterval(interval);
    }, [parking])

    return (
        <div className="card parking-card" onClick={onClick}>
            <div className="d-flex justify-content-between align-items-center">
                <div className="car-image" style={{backgroundImage: `url('${parking['car']['image'] !== '' ? parking['car']['image'] : ''}')`, backgroundSize: 'cover'}}>
                    <div className="status">
                        <div className={parking.status === "Checked-In" ? "status-ongoing" : "status-completed"}/>
                    </div>
                </div>
                <div className="timing">
                    {parking['status'] === "Parked" ? <p>{moment(parking['timings']['parked']).diff(moment(parking['timings']['arrival']), 'hours')}:{moment(parking['timings']['parked']).diff(moment(parking['timings']['arrival']), 'minutes')}:{moment(parking['timings']['parked']).diff(moment(parking['timings']['arrival']), 'seconds') % 60}</p> : <p>{checkInTimer}</p>}
                </div>
            </div>
            <p className="guest-name">{parking['guest']['firstName']} {parking['guest']['lastName']}</p>
            <p className="guest-license-plate">Ticket : #{parking['ticket']}</p>
            <div className="row parking-details">
                <div className="col-12 d-flex align-items-center detail-item">
                    <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="map"/></div>
                    <div className="detail">{parking['venueDetails']['name']}</div>
                </div>
                <div className="col-12 d-flex align-items-center detail-item">
                    <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="users"/></div>
                    <div className="detail">{parking['valetDetails']['name']}</div>
                </div>
                {parking['guest']['phoneNumber'] !== '' ? <div className="col-12 d-flex align-items-center detail-item">
                    <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon
                        icon="phone"/></div>
                    <div className="detail">{parking['guest']['phoneNumber']}</div>
                </div> : <div className="col-12 d-flex align-items-center detail-item">
                    <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon
                        icon="mail"/></div>
                    <div className="detail">{parking['guest']['email']}</div>
                </div>}
            </div>
        </div>
    );
}

export default ParkingCard;
