import axios, {AxiosError} from "axios";
import baseConfig from "../../Config";


export const upload = async (body) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": token,
                "enctype": "multipart/form-data"
            },
        };
        try {
            let res = await axios.post(
                `${baseConfig.apiBaseUrl}/utility/aws/upload-files`,
                body,
                config
            );
            if (res.data.status) {
                return res.data.data;
            } else {
                return [];
            }
        } catch (e) {

        }

    }

}
