import FeatherIcon from 'feather-icons-react';
import { useEffect } from "react";
import $ from 'jquery';
import { Link } from "react-router-dom";

import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { useLocation } from "react-router";


const Sidebar = () => {

    const user = useSelector((state) => state.auth);
    const location = useLocation();

    useEffect(() => {
        $('#sidebar-menu a').on('click', function (e) {
            if ($(this).parent().hasClass('submenu')) {
                e.preventDefault();
            }
            if (!$(this).hasClass('subdrop')) {
                $('ul', $(this).parents('ul:first')).slideUp(350);
                $('a', $(this).parents('ul:first')).removeClass('subdrop');
                $(this).next('ul').slideDown(350);
                $(this).addClass('subdrop');
            } else if ($(this).hasClass('subdrop')) {
                $(this).removeClass('subdrop');
                $(this).next('ul').slideUp(350);
            }
        });
        $('#sidebar-menu ul li.submenu a.active').parents('li:last').children('a:first').addClass('active').trigger('click');
    }, [user.token]);

    return <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
                <ul>
                    <li className="menu-title"><span>Dashboard</span></li>
                    {user.allowedPaths.includes('/') ? <>
                        <li>
                            <Link to="/"><FeatherIcon icon="home" /><span>Dashboard</span></Link>
                        </li>
                    </> : <></>}

                    <li className="menu-title"><span>Venues</span></li>
                    {user.allowedPaths.includes('/venue-type') || user.allowedPaths.includes('/') ? <>
                        <li className="submenu">
                            <a href="#"><FeatherIcon icon="filter" /> <span> Venue type</span> <span
                                className="menu-arrow" /></a>
                            <ul>
                                <li><Link to='/venue-type/add'>Add venue type</Link></li>
                                <li><Link to='/venue-type'>Venue type list</Link></li>
                            </ul>
                        </li>
                    </> : <></>}

                    {user.allowedPaths.includes('/venue') || user.allowedPaths.includes('/') ? <>
                        <li className="submenu">
                            <a href="#"><FeatherIcon icon="map" /> <span> Venue</span> <span
                                className="menu-arrow" /></a>
                            <ul>
                                <li><Link to='/venue/add'>Add venue</Link></li>
                                <li><Link to='/venue'>Venue list</Link></li>
                            </ul>
                        </li>
                    </> : <></>}


                    <li className="menu-title"><span>Staff</span></li>

                    {user.allowedPaths.includes('/staff/manager') || user.allowedPaths.includes('/') ? <>
                        <li className="submenu">
                            <a href="#"><FeatherIcon icon="user-x" /> <span> Manager</span> <span
                                className="menu-arrow" /></a>
                            <ul>
                                <li><Link to='/staff/manager/add'>Add manager</Link></li>
                                <li><Link to='/staff/manager'>Manager list</Link></li>
                            </ul>
                        </li>
                    </> : <></>}

                    {user.allowedPaths.includes('/staff/valet') || user.allowedPaths.includes('/') ? <>
                        <li className="submenu">
                            <a href="#"><FeatherIcon icon="users" /> <span> Valet</span> <span
                                className="menu-arrow" /></a>
                            <ul>
                                <li><Link to='/staff/valet/add'>Add valet</Link></li>
                                <li><Link to='/staff/valet'>Valet list</Link></li>
                            </ul>
                        </li>
                    </> : <></>}

                    {user.allowedPaths.includes('/staff/admin') || user.allowedPaths.includes('/') ? <>
                        <li className="submenu">
                            <a href="#"><FeatherIcon icon="user-x" /> <span> Admin</span> <span
                                className="menu-arrow" /></a>
                            <ul>
                                <li><Link to='/staff/admin/add'>Add admin</Link></li>
                                <li><Link to='/staff/admin'>Admin list</Link></li>
                            </ul>
                        </li>
                    </> : <></>}




                    <li className="menu-title"><span>Operation</span></li>

                    {user.allowedPaths.includes('/parking') || user.allowedPaths.includes('/') ? <>
                        <li className="submenu">
                            <a href="#"><FeatherIcon icon="tag" /> <span> Parking</span> <span
                                className="menu-arrow" /></a>
                            <ul>
                                <li><Link to='/parking'>Parking List</Link></li>
                                <li><Link to='/parking-card'>Parking Card</Link></li>
                            </ul>
                        </li>
                    </> : <></>}

                    {user.allowedPaths.includes('/request') || user.allowedPaths.includes('/') ? <>
                        <li className="submenu">
                            <a href="#"><FeatherIcon icon="octagon" /> <span> Request</span> <span
                                className="menu-arrow" /></a>
                            <ul>
                                <li><Link to='/request'>Request List</Link></li>
                                <li><Link to='/request-card'>Request Card</Link></li>
                            </ul>
                        </li>
                    </> : <></>}

                    <li className="menu-title"><span>Settings</span></li>
                    {user.allowedPaths.includes('/config') || user.allowedPaths.includes('/') ? <>
                        <li className="submenu">
                            <a href="#"><FeatherIcon icon="filter" /> <span> App Settings</span> <span
                                className="menu-arrow" /></a>
                            <ul>
                                <li><Link to='/configs/add'>Add setting</Link></li>
                                <li><Link to='/configs'>Settings list</Link></li>
                            </ul>
                        </li>
                    </> : <></>}


                </ul>
            </div>
        </div>
    </div>;
}

export default Sidebar;
