import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';


const initialState = { id: null, token: null, email: null, name: null, username: null, profilePic: null, type: null, permissions: [], allowedPaths: [], venues: [] }

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess(state, action) {
            state.id = action.payload.data.id;
            state.token = action.payload.data.token;
            state.email = action.payload.data.email;
            state.name = action.payload.data.name;
            state.username = action.payload.data.username;
            state.profilePic = action.payload.data.profilePic;
            state.type = action.payload.data.type;
            state.permissions = action.payload.data.permissions;
            state.allowedPaths = action.payload.data.allowedPaths;
            state.venues = action.payload.data.venues;
            localStorage.setItem("token", action.payload.data.token);
            toast.success(action.payload.message, {});
        },
        loginFailed(state, action) {
            toast.error(action.payload.message, {});
        },
        refreshTokenSuccess(state, action) {
            state.id = action.payload.data.id;
            state.token = action.payload.data.token;
            state.email = action.payload.data.email;
            state.name = action.payload.data.name;
            state.username = action.payload.data.username;
            state.profilePic = action.payload.data.profilePic;
            state.type = action.payload.data.type;
            state.permissions = action.payload.data.permissions;
            state.allowedPaths = action.payload.data.allowedPaths;
            state.venues = action.payload.data.venues;
            localStorage.setItem("token", action.payload.data.token);
        },
        refreshTokenFailed(state, action) {
            state.id = null;
            state.token = null;
            state.email = null;
            state.name = null;
            state.username = null;
            state.profilePic = null;
            state.type = null;
            state.permissions = [];
            state.allowedPaths = [];
            state.venues = [];
            localStorage.setItem("token", null);

        },
        logoutSuccess(state) {
            state.token = null;
            state.email = null;
            state.name = null;
            state.username = null;
            state.profilePic = null;
            state.type = null;
            state.permissions = [];
            state.allowedPaths = [];
            state.venues = [];
            localStorage.removeItem("token");
        },
        profileUpdateSuccess(state, action) {
            state.email = action.payload.data.email;
            state.name = action.payload.data.name;
            toast.success(action.payload.message, {});
        },
        profileUpdateFailed(state, action) {
            toast.error(action.payload.message, {});
        },
    },
})

export const { loginSuccess, loginFailed, refreshTokenSuccess, refreshTokenFailed, logoutSuccess, profileUpdateSuccess, profileUpdateFailed } = authSlice.actions
export default authSlice.reducer
