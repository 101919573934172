import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net';
import { useLocation, useNavigate, useParams } from "react-router";
import store from "../../store";
import moment from "moment";

const ManageNotification = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const allNotifications = useSelector((state) => state.notification.notifications);
    const [notifications, setNotifications] = useState([]);



    useEffect(() => {
        try {
            $('#table').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        $('#table').DataTable({
            data: notifications,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        return `<p class="badge bg-primary" style="cursor: pointer">#${data}</p>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            console.log('CLICKED');
                            navigate(`/parking/${cellData}`);
                        })

                    },
                    "targets": 3
                },
            ],
            order: []
        });
    }, [notifications]);



    useEffect(() => {
        let _notifications = [];
        let count = 1;
        allNotifications.forEach((notification) => {
            const duration = moment.duration(moment().diff(moment(notification.createdAt)));
            const hours = parseInt(duration.asHours().toString());
            const minutes = parseInt(duration.asMinutes().toString());
            _notifications.push([count, notification.title, notification.message, notification.payload.ticket, hours > 24 ? moment(notification.createdAt || "").format('MMMM Do YYYY, h:mm:ss a') : minutes > 60 ? `${hours} hours ago` : `${minutes} min ago`])
            count++;
        });
        setNotifications(_notifications);
    }, [allNotifications])

    return (
        <div>
            <div class="card mt-3">
                <div className="card-header">
                    <h5 className="card-title">Manage Notifications</h5>
                </div>
                <div class="card-body">
                    <div className='table-responsive'>
                        <table
                            id="table"
                            className='table datatable'
                        >
                            <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Title</th>
                                <th>Message</th>
                                <th>Ticket</th>
                                <th>Time</th>
                            </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default ManageNotification;
