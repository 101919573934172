import axios from "axios";
import baseConfig from "../../Config";
import {
    slotLoaded,
    venueAddFailed, venueDeleteFailed, venueEditFailed, venueLoaded,
    venueTypeAddFailed,
    venueTypeDeleteFailed,
    venueTypeEditFailed,
    venueTypeLoaded
} from "./reducer";
import {toast} from "react-toastify";

export const addVenueType = (data) => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        const body = JSON.stringify(data);
        try {
            let res = await axios.post(
                `${baseConfig.apiBaseUrl}/admin/venue/venue-type`,
                body,
                config
            );
            if (!res.data['status']) {
                dispatch({
                    type: venueTypeAddFailed,
                    payload: res.data,
                });
            }
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: venueTypeEditFailed,
                    payload: err.response.data,
                });
            }
        }
    }
}

export const getAllVenueType = () => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        let page = 1;
        let limit = 100;
        while (page !== 0) {
            try {
                let res = await axios.get(
                    `${baseConfig.apiBaseUrl}/admin/venue/venue-type/all?page=${page}&limit=${limit}`,
                    config
                );
                console.log(res.data);
                if (res.data['status']) {
                    if (res.data.data.length !== 0) {
                        dispatch({
                            type: venueTypeLoaded,
                            payload: res.data,
                        });
                        page++;
                    } else {
                        page = 0;
                    }
                }
            } catch (err) {
                if (err.response) {
                    dispatch({
                        type: venueTypeEditFailed,
                        payload: err.response.data,
                    });
                }
            }
        }

    }
}

export const editVenueType = (id, data) => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        const body = JSON.stringify(data);
        try {
            let res = await axios.patch(
                `${baseConfig.apiBaseUrl}/admin/venue/venue-type?id=${id}`,
                body,
                config
            );
            if (!res.data['status']) {
                dispatch({
                    type: venueTypeEditFailed,
                    payload: res.data,
                });
            }
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: venueTypeEditFailed,
                    payload: err.response.data,
                });
            }
        }
    }
}

export const changeVenueTypeStatus = (id) => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.patch(
                `${baseConfig.apiBaseUrl}/admin/venue/venue-type/change-status?id=${id}`,
                {},
                config
            );
            if (!res.data['status']) {
                dispatch({
                    type: venueTypeEditFailed,
                    payload: res.data,
                });
            }
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: venueTypeEditFailed,
                    payload: err.response.data,
                });
            }
        }
    }
}

export const deleteVenueType = (id) => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.delete(
                `${baseConfig.apiBaseUrl}/admin/venue/venue-type?id=${id}`,
                config
            );
            if (!res.data['status']) {
                dispatch({
                    type: venueTypeDeleteFailed,
                    payload: res.data,
                });
            }
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: venueTypeDeleteFailed,
                    payload: err.response.data,
                });
            }
        }
    }
}


export const addVenue = (data) => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        const body = JSON.stringify(data);
        try {
            let res = await axios.post(
                `${baseConfig.apiBaseUrl}/admin/venue/venue`,
                body,
                config
            );

            console.log(res.data);
            if (!res.data['status']) {
                dispatch({
                    type: venueAddFailed,
                    payload: res.data,
                });
            }
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: venueTypeEditFailed,
                    payload: err.response.data,
                });
            }
        }
    }
}

export const getAllVenue = () => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        let page = 1;
        let limit = 100;
        while (page !== 0) {
            try {
                let res = await axios.get(
                    `${baseConfig.apiBaseUrl}/admin/venue/venue/all?page=${page}&limit=${limit}`,
                    config
                );
                console.log(res.data);
                if (res.data['status']) {
                    if (res.data.data.length !== 0) {
                        dispatch({
                            type: venueLoaded,
                            payload: res.data,
                        });
                        page++;
                    } else {
                        page = 0;
                    }
                }
            } catch (err) {
                if (err.response) {
                    dispatch({
                        type: venueTypeEditFailed,
                        payload: err.response.data,
                    });
                }
            }
        }

    }
}

export const getAllSlot = () => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        let page = 1;
        let limit = 100;
        while (page !== 0) {
            try {
                let res = await axios.get(
                    `${baseConfig.apiBaseUrl}/admin/venue/slot/all?page=${page}&limit=${limit}`,
                    config
                );
                console.log(res.data);
                if (res.data['status']) {
                    if (res.data.data.length !== 0) {
                        dispatch({
                            type: slotLoaded,
                            payload: res.data,
                        });
                        page++;
                    } else {
                        page = 0;
                    }
                }
            } catch (err) {
                if (err.response) {
                    dispatch({
                        type: venueTypeEditFailed,
                        payload: err.response.data,
                    });
                }
            }
        }

    }
}

export const changeVenueStatus = (id) => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.patch(
                `${baseConfig.apiBaseUrl}/admin/venue/venue/change-status?id=${id}`,
                {},
                config
            );
            if (!res.data['status']) {
                dispatch({
                    type: venueEditFailed,
                    payload: res.data,
                });
            }
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: venueEditFailed,
                    payload: err.response.data,
                });
            }
        }
    }
}

export const deleteVenue = (id) => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.delete(
                `${baseConfig.apiBaseUrl}/admin/venue/venue?id=${id}`,
                config
            );
            if (!res.data['status']) {
                dispatch({
                    type: venueDeleteFailed,
                    payload: res.data,
                });
            }
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: venueDeleteFailed,
                    payload: err.response.data,
                });
            }
        }
    }
}

export const updateVenue = (id, data) => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        const body = JSON.stringify(data);
        try {
            let res = await axios.patch(
                `${baseConfig.apiBaseUrl}/admin/venue/venue?id=${id}`,
                body,
                config
            );

            console.log(res.data);
            if (!res.data['status']) {
                dispatch({
                    type: venueEditFailed,
                    payload: res.data,
                });
            }
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: venueEditFailed,
                    payload: err.response.data,
                });
            }
        }
    }
}


export const getVenueCardStats = async (id) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/venue/card-stats?id=${id}`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}

export const getParkingSlotDistribution = async (id) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/venue/parking-slot-distribution?id=${id}`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}

export const getMonthlyRevenueAnalytics = async (id) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/venue/revenue/monthly?id=${id}`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}

export const getWeeklyRevenueAnalytics = async (id) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/venue/revenue/weekly?id=${id}`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}

export const getYearlyRevenueAnalytics = async (id) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/venue/revenue/yearly?id=${id}`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}

export const assignStaffByVenue = async (id, data) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        const body = JSON.stringify(data);
        try {
            let res = await axios.patch(
                `${baseConfig.apiBaseUrl}/admin/staff/assign?id=${id}`,
                body,
                config
            );

            console.log(res.data);

            toast.info(res.data.message, {});

        } catch (err) {
            if (err.response) {

            }
        }
    }
}

export const getMonthlyParkingsAndRequests = async (id) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/venue/parkings/monthly?id=${id}`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}

export const getWeeklyParkingsAndRequests = async (id) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/venue/parkings/weekly?id=${id}`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}

export const getDailyParkingsAndRequests = async (id) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/venue/parkings/daily?id=${id}`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}

export const getVenueRevenueComparisonAnalytics = async (id, data) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.post(
                `${baseConfig.apiBaseUrl}/admin/venue/revenue/compare?id=${id}`,
                data,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}
