import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';


const initialState = { mobileNavOpen: false }

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        toggleNavOpen(state, action) {
            state.mobileNavOpen = state.payload
        }
    },
})

export const { toggleNavOpen } = commonSlice.actions
export default commonSlice.reducer
