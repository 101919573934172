import { Route } from "react-router-dom";
import { Routes } from "react-router";
import React, { useEffect, useState } from 'react'
import AuthMiddleware from "./middleware/AuthMiddleware";
import Dashboard from "./pages/Dashboard";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import { refreshToken } from "./store/auth/action";
import store from "./store";
import EditProfile from "./pages/authentication/EditProfile";
import ManageVenueType from "./pages/venueType/List";
import AddVenueType from "./pages/venueType/Add";
import { socketSync } from "./store/socket/action";
import { getAllSlot, getAllVenue, getAllVenueType } from "./store/venue/action";
import EditVenueType from "./pages/venueType/Edit";
import ManageVenue from "./pages/venue/List";
import AddVenue from "./pages/venue/Add";
import EditVenue from "./pages/venue/Edit";
import ManageAdminStaff from "./pages/staff/List";
import AddAdminStaff from "./pages/staff/Add";
import { getAllPermissions, getAllStaff } from "./store/staff/action";
import EditAdminStaff from "./pages/staff/Edit";
import { useSelector } from "react-redux";
import ManageParking from "./pages/parking/List";
import { getAllParking, getAllRequest } from "./store/parking/action";
import ParkingDetails from "./pages/parking/Details";
import ManageRequests from "./pages/request/List";
import RequestDetails from "./pages/request/Details";
import ParkingListCard from "./pages/parking/ListCard";
import RequestListCard from "./pages/request/ListCard";
import { getAllConfigs } from "./store/config/action";
import AddConfig from "./pages/config/Add";
import ManageConfig from "./pages/config/List";
import EditConfig from "./pages/config/Edit";
import {getAllNotifications} from "./store/notification/action";
import ManageNotification from "./pages/notification/List";
import VenueDetails from "./pages/venue/Detail";
import Login from "./pages/authentication/Login";
require('dotenv').config()


const App = () => {
    const user = useSelector((state) => state.auth);
    const authToken = useSelector((state) => state.auth.token);
    const [initialized, setInitialized] = useState(false);
    console.log(process.env)

    useEffect(() => {
        if (authToken !== null && !initialized) {
            store.dispatch(socketSync(user));
            store.dispatch(getAllVenueType());
            store.dispatch(getAllVenue());
            store.dispatch(getAllSlot());
            store.dispatch(getAllStaff());
            store.dispatch(getAllPermissions());
            store.dispatch(getAllParking());
            store.dispatch(getAllRequest());
            store.dispatch(getAllConfigs());
            store.dispatch(getAllNotifications());
            setInitialized(true);
        }
    }, [authToken]);

    useEffect(() => {
        if (!initialized) {
            store.dispatch(refreshToken());
        }
    }, [initialized]);



    return (
        <>
            <Helmet>
                <script src="/assets/js/script.js" type="text/javascript" />

            </Helmet>
            <Routes>
                <Route path="/login" element={
                    <Login />
                } />
                <Route path="/" element={
                    <AuthMiddleware>
                        <Dashboard />
                    </AuthMiddleware>
                } />
                <Route path="/edit-profile" element={
                    <AuthMiddleware>
                        <EditProfile />
                    </AuthMiddleware>
                } />
                <Route path="/venue-type" element={
                    <AuthMiddleware>
                        <ManageVenueType />
                    </AuthMiddleware>
                } />
                <Route path="/venue-type/add" element={
                    <AuthMiddleware>
                        <AddVenueType />
                    </AuthMiddleware>
                } />
                <Route path="/venue-type/edit/:venueType" element={
                    <AuthMiddleware>
                        <EditVenueType />
                    </AuthMiddleware>
                } />
                <Route path="/venue" element={
                    <AuthMiddleware>
                        <ManageVenue />
                    </AuthMiddleware>
                } />
                <Route path="/venue/add" element={
                    <AuthMiddleware>
                        <AddVenue />
                    </AuthMiddleware>
                } />
                <Route path="/venue/edit/:venue" element={
                    <AuthMiddleware>
                        <EditVenue />
                    </AuthMiddleware>
                } />
                <Route path="/venue/details/:venue" element={
                    <AuthMiddleware>
                        <VenueDetails />
                    </AuthMiddleware>
                } />
                <Route path="/staff/:type" element={
                    <AuthMiddleware>
                        <ManageAdminStaff />
                    </AuthMiddleware>
                } />
                <Route path="/staff/:type/add" element={
                    <AuthMiddleware>
                        <AddAdminStaff />
                    </AuthMiddleware>
                } />
                <Route path="/staff/:type/edit/:staff" element={
                    <AuthMiddleware>
                        <EditAdminStaff />
                    </AuthMiddleware>
                } />

                <Route path="/parking" element={
                    <AuthMiddleware>
                        <ManageParking />
                    </AuthMiddleware>
                } />

                <Route path="/parking-card" element={
                    <AuthMiddleware>
                        <ParkingListCard />
                    </AuthMiddleware>
                } />

                <Route path="/parking/:ticket" element={
                    <AuthMiddleware>
                        <ParkingDetails />
                    </AuthMiddleware>
                } />

                <Route path="/request" element={
                    <AuthMiddleware>
                        <ManageRequests />
                    </AuthMiddleware>
                } />

                <Route path="/request-card" element={
                    <AuthMiddleware>
                        <RequestListCard />
                    </AuthMiddleware>
                } />

                <Route path="/request/:ticket" element={
                    <AuthMiddleware>
                        <RequestDetails />
                    </AuthMiddleware>
                } />

                <Route path="/configs/add" element={
                    <AuthMiddleware>
                        <AddConfig />
                    </AuthMiddleware>
                } />
                <Route path="/configs" element={
                    <AuthMiddleware>
                        <ManageConfig />
                    </AuthMiddleware>
                } />
                <Route path="/configs/edit/:config" element={
                    <AuthMiddleware>
                        <EditConfig />
                    </AuthMiddleware>
                } />
                <Route path="/notifications" element={
                    <AuthMiddleware>
                        <ManageNotification />
                    </AuthMiddleware>
                } />
            </Routes>
            <ToastContainer position="top-right"
                autoClose={2000} />
        </>
    );
}

export default App;
