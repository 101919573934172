import React, {useEffect, useState} from 'react';
import 'datatables.net-bs4';
import 'datatables.net';
import {useLocation, useNavigate} from "react-router";
import {useSelector} from "react-redux";
import $ from "jquery";
import store from "../../store";
import {changeVenueStatus, deleteVenue} from "../../store/venue/action";
import moment from "moment";

const ManageVenue = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const allVenues = useSelector((state) => state.venue.venues)
    const allStaff = useSelector((state) => state.staff.admins);
    const allParking = useSelector((state) => state.parking.parking);
    const [venues, setVenues] = useState([]);

    useEffect(() => {
        try {
            $('#table').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        $('#table').DataTable({
            data: venues,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        if (data) {
                            return `<div class="figure"><img src="${data}" style="width: 60px;height: 60px; border-radius: 5px; object-fit: cover;"></div>`;
                        }
                        return '<div></div>';
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 0
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary"><i class="fa fa-eye"></i></button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            console.log('CLICKED');
                            navigate(`${location.pathname}/details/${cellData}`);
                        })
                    },
                    "targets": 6
                },
                {

                    "render": function (data, type, row) {
                        if (data === 'Active') {
                            return `<p class="badge bg-success">Active</p>`;
                        } else {
                            return `<p class="badge bg-danger">Deactive</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 3
                },
                {

                    "render": function (data, type, row) {
                        if (row[3] === 'Active') {
                            return `<button class="btn btn-danger"><i class="fa fa-times-circle me-2"></i>Deactivate</button>`;
                        } else {
                            return `<button class="btn btn-success"><i class="fa fa-check-circle me-2"></i>Activate</button>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            store.dispatch(changeVenueStatus(cellData));
                        })
                    },
                    "targets": 7
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-danger"><i class="fa fa-trash me-2"></i>Delete</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            store.dispatch(deleteVenue(cellData));
                        })
                    },
                    "targets": 8
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary"><i class="fa fa-pen"></i></button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            console.log('CLICKED');
                            navigate(`${location.pathname}/edit/${cellData}`);
                        })
                    },
                    "targets": 5
                },

            ],
            rowCallback: function (row, data, index) {
                try {
                    console.log(data);
                    const parkedVehicles = allParking.filter(parking => parking.venue.id === data[8] && parking.status === 'Parked').length;
                    const availableValets = allStaff.filter(staff => staff.venues.includes(data[8]) && staff.status === 'Active' && staff.available).length;
                    console.log(parkedVehicles, availableValets);
                    $('td', row).attr('data-bs-toggle', 'tooltip');
                    $('td', row).attr('data-bs-placement', 'top');
                    $('td', row).attr('title', `Parked - ${parkedVehicles} | Available - ${availableValets}`);
                } catch (e) {
                    console.log(e);
                }
            },
            order: []
        });
    }, [venues])

    useEffect(() => {
        let _venues = [];
        allVenues.forEach((venue) => {
            if (!venue.deleted) {
                _venues.push([venue.image || "", venue.name, venue.description || '', venue.status, moment(venue.createdAt || "").format('MMMM Do YYYY, h:mm:ss a'), venue._id, venue._id, venue._id, venue._id]);
            }
        });
        setVenues(_venues);
    }, [allVenues, allParking, allStaff])

    return (
        <div>
            <div class="card mt-3">
                <div className="card-header">
                    <h5 className="card-title">Manage venue</h5>
                </div>
                <div class="card-body">
                    <div className='table-responsive'>
                        <table
                            id="table"
                            className='table datatable table-hover'
                        >
                            <thead>
                            <tr>
                                <th>Image</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Status</th>
                                <th>Date</th>
                                <th>Edit</th>
                                <th>Details</th>
                                <th>Change Status</th>
                                <th>Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default ManageVenue;
