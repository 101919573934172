import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const initialState = { notifications: [] }

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        addNotificationSuccess(state, action) {
            if (action.payload) {
                state.notifications.push(action.payload);
            }
        },
        addNotificationFailed(state, action) {
            toast.warning(action.payload.message, {});
        },
        notificationLoaded(state, action) {
            state.notifications.push(...action.payload.data);
        },
        notificationDeleteSuccess(state, action) {
            if (action.payload.data) {
                state.notifications = state.notifications.filter(notification => notification._id !== action.payload.data._id);
                toast.info(action.payload.message, {});
            }
        },
        notificationDeleteFailed(state, action) {
            toast.warning(action.payload.message, {});
        },
        notificationEditSuccess(state, action) {
            if (action.payload.data) {
                let index = state.notifications.findIndex(notification => notification._id === action.payload.data._id);
                if (index >= 0) {
                    state.notifications[index] = action.payload.data;
                    toast.info(action.payload.message, {});
                }
            }
        },
        notificationEditFailed(state, action) {
            toast.warning(action.payload.message, {});
        },
        resetNotification(state, action) {
            state.notifications = [];
        },
    },
})

export const { addNotificationSuccess, addNotificationFailed, notificationLoaded, notificationDeleteSuccess, notificationDeleteFailed, notificationEditSuccess, notificationEditFailed, resetNotification } = notificationSlice.actions
export default notificationSlice.reducer
