import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {useSelector} from "react-redux";
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net';
import {useLocation, useNavigate} from "react-router";
import store from "../../store";
import {changeVenueTypeStatus, deleteVenueType, deleteVenueTypeStatus} from "../../store/venue/action";
import moment from "moment";

const ManageVenueType = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const allVenueTypes = useSelector((state) => state.venue.venueTypes)
    const [venueTypes, setVenueTypes] = useState([]);

    useEffect(() => {
        console.log("MODIFIED")
        try {
            $('#table').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        $('#table').DataTable({
            data: venueTypes,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        if (data) {
                            return `<div class="figure"><img src="${data}" style="width: 60px;height: 60px; border-radius: 5px"></div>`;
                        }
                        return '<div></div>';
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 0
                },
                {

                    "render": function (data, type, row) {
                        if (data === 'Active') {
                            return `<p class="badge bg-success">Active</p>`;
                        } else {
                            return `<p class="badge bg-danger">Deactive</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 3
                },
                {

                    "render": function (data, type, row) {
                        if (row[3] === 'Active') {
                            return `<button class="btn btn-danger"><i class="fa fa-times-circle me-2"></i>Deactivate</button>`;
                        } else {
                            return `<button class="btn btn-success"><i class="fa fa-check-circle me-2"></i>Activate</button>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            store.dispatch(changeVenueTypeStatus(cellData));
                        })
                    },
                    "targets": 6
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-danger"><i class="fa fa-trash me-2"></i>Delete</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            store.dispatch(deleteVenueType(cellData));
                        })
                    },
                    "targets": 7
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary"><i class="fa fa-pen me-2"></i>Edit</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            console.log('CLICKED');
                            navigate(`${location.pathname}/edit/${cellData}`);
                        })
                    },
                    "targets": 5
                },
            ],
            order: []
        });
    }, [venueTypes]);

    useEffect(() => {
        let _venueTypes = [];
        allVenueTypes.forEach((venueType) => {
           if (!venueType.deleted) {
               _venueTypes.push([venueType.image || "", venueType.title, venueType.description, venueType.status, moment(venueType.createdAt || "").format('MMMM Do YYYY, h:mm:ss a'), venueType._id, venueType._id, venueType._id])
           }
        });
        setVenueTypes(_venueTypes);
    }, [allVenueTypes])

    return (
        <div>
            <div class="card mt-3">
                <div className="card-header">
                    <h5 className="card-title">Manage venue type</h5>
                </div>
                <div class="card-body">
                    <div className='table-responsive'>
                        <table
                            id="table"
                            className='table datatable'
                        >
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Date</th>
                                    <th>Details</th>
                                    <th>Change Status</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default ManageVenueType;
