import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';


const initialState = { parking: [], request: [] }

const parkingSlice = createSlice({
    name: 'parking',
    initialState,
    reducers: {
        parkingLoadSuccess(state, action) {
            state.parking.push(...action.payload.data)
        },
        checkInSuccess(state, action) {
            state.parking.push(action.payload.data)
            toast.info(action.payload.message, {});
        },
        operationFailed(state, action) {
            toast.warning(action.payload.message, {});
        },
        parkingAddSuccess(state, action) {
            state.parking.splice(0, 0, action.payload.data)
            toast.info(action.payload.message, {});
        },
        parkingEditSuccess(state, action) {
            if (action.payload.data) {
                let index = state.parking.findIndex(p => p._id === action.payload.data._id);
                if (index >= 0) {
                    state.parking[index] = action.payload.data;
                    toast.info(action.payload.message, {});
                }
            }
        },
        requestLoadSuccess(state, action) {
            state.request.push(...action.payload.data);
        },
        vehicleRequested(state, action) {
            state.request.push(action.payload.data);
            toast.info(action.payload.message, {});
        },
        vehicleRequestUpdated(state, action) {
            if (action.payload.data) {
                let index = state.request.findIndex(r => r._id === action.payload.data._id);
                if (index >= 0) {
                    state.request[index] = action.payload.data;
                    toast.info(action.payload.message, {});
                }
            }
        },
    },
})

export const { checkInSuccess, parkingLoadSuccess, operationFailed, parkingAddSuccess, parkingEditSuccess, requestLoadSuccess, vehicleRequested, vehicleRequestUpdated } = parkingSlice.actions
export default parkingSlice.reducer
