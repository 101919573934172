import axios from "axios";
import baseConfig from "../../Config";
import {
    configEditSuccess,
    addConfigFailed,
    addConfigSuccess,
    configLoaded,
    configDeleteSuccess,
    configDeleteFailed,
} from "./reducer";

export const updateConfig = (id, data) => async (dispatch) => {
    let token = localStorage.getItem("token");
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        };
        try {
            let res = await axios.patch(
                `${baseConfig.apiBaseUrl}/admin/config?id=${id}`,
                data,
                config
            );

            if (res.data.status) {
                dispatch({
                    type: configEditSuccess,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: addConfigFailed,
                    payload: res.data,
                });
            }
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: addConfigFailed,
                    payload: err.response.data,
                });
            }
        }
    }
};

export const addConfig = (data) => async (dispatch) => {
    let token = localStorage.getItem('token');
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        try {
            let res = await axios.post(
                `${baseConfig.apiBaseUrl}/admin/config`,
                data,
                config
            );

            if (!res.data['status']) {
                dispatch({
                    type: addConfigFailed,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: addConfigSuccess,
                    payload: res.data,
                });
            }
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: addConfigFailed,
                    payload: err.response.data,
                });
            }
        }

    }
}

export const getAllConfigs = () => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        let page = 1;
        let limit = 100;
        while (page !== 0) {
            try {
                let res = await axios.get(
                    `${baseConfig.apiBaseUrl}/admin/config/all?page=${page}&limit=${limit}`,
                    config
                );
                console.log(res.data);
                if (res.data['status']) {
                    if (res.data.data.length !== 0) {
                        dispatch({
                            type: configLoaded,
                            payload: res.data,
                        });
                        page++;
                    } else {
                        page = 0;
                    }
                }
            } catch (err) {
                if (err.response) {
                    dispatch({
                        type: addConfigFailed,
                        payload: err.response.data,
                    });
                }
            }
        }

    }
}

export const deleteConfig = (id) => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.delete(
                `${baseConfig.apiBaseUrl}/admin/config?id=${id}`,
                config
            );
            if (res.data['status']) {
                dispatch({
                    type: configDeleteSuccess,
                    payload: res.data,
                });
            }
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: configDeleteFailed,
                    payload: err.response.data,
                });
            }
        }
    }
}
