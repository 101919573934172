import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net';
import { useLocation, useNavigate, useParams } from "react-router";
import store from "../../store";
import { changeStaffStatus, deleteStaff } from "../../store/staff/action";
import {deleteConfig} from "../../store/config/action";

const ManageConfig = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const allConfig = useSelector((state) => state.config.config);
    const [configs, setConfigs] = useState([]);



    useEffect(() => {
        try {
            $('#table').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        $('#table').DataTable({
            data: configs,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-danger"><i class="fa fa-trash me-2"></i>Delete</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            store.dispatch(deleteConfig(cellData));
                        })
                    },
                    "targets": 5
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary"><i class="fa fa-pen me-2"></i>Edit</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            console.log('CLICKED');
                            navigate(`${location.pathname}/edit/${cellData}`);
                        })
                    },
                    "targets": 4
                },
            ],
            order: []
        });
    }, [configs]);



    useEffect(() => {
        let _configs = [];
        allConfig.forEach((config) => {
            if (!config.deleted) {
                _configs.push([config.type, config.title, config.value, config.createdAt, config._id, config._id])

            }
        });
        setConfigs(_configs);
    }, [allConfig])

    return (
        <div>
            <div class="card mt-3">
                <div className="card-header">
                    <h5 className="card-title">Manage Settings</h5>
                </div>
                <div class="card-body">
                    <div className='table-responsive'>
                        <table
                            id="table"
                            className='table datatable'
                        >
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Title</th>
                                    <th>Value</th>
                                    <th>Date</th>
                                    <th>Details</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default ManageConfig;
