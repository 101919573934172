import {createSlice} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';


const initialState = {admins: [], permissions: []}

const staffSlice = createSlice({
    name: 'staff',
    initialState,
    reducers: {
        addStaffSuccess(state, action) {
            if (action.payload.data) {
                state.admins.push(action.payload.data);
                toast.info(action.payload.message, {});
            }
        },
        addStaffFailed(state, action) {
            toast.warning(action.payload.message, {});
        },
        staffLoaded(state, action) {
            state.admins.push(...action.payload.data);
        },
        permissionLoadedSuccess(state, action) {
            state.permissions = action.payload.data;
        },
        staffDeleteSuccess(state, action) {
            if (action.payload.data) {
                state.admins = state.admins.filter(admin => admin._id !== action.payload.data._id);
                toast.info(action.payload.message, {});
            }
        },
        staffDeleteFailed(state, action) {
            toast.warning(action.payload.message, {});
        },
        staffEditSuccess(state, action) {
            if (action.payload.data) {
                let index = state.admins.findIndex(admin => admin._id === action.payload.data._id);
                if (index >= 0) {
                    state.admins[index] = action.payload.data;
                    toast.info(action.payload.message, {});
                }
            }
        },
        staffEditFailed(state, action) {
            toast.warning(action.payload.message, {});
        },
    },
})

export const {addStaffSuccess, addStaffFailed, staffLoaded, permissionLoadedSuccess, staffDeleteSuccess, staffDeleteFailed, staffEditSuccess, staffEditFailed} = staffSlice.actions
export default staffSlice.reducer
