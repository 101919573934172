import React, { useEffect, useState } from 'react';
import { upload } from "../../store/utility/action";
import Button from 'react-bootstrap-button-loader';
import store from "../../store";
import { addVenue, addVenueType } from "../../store/venue/action";
import { useSelector } from "react-redux";
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={8}
        defaultCenter={{ lat: -34.397, lng: 150.644 }}
        onClick={props.onClick}
    >

    </GoogleMap>
))


const AddVenue = () => {

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [venueType, setVenueType] = useState("");
    const [parkingPrice, setParkingPrice] = useState({
        regular: 0,
        premium: 0,
        complementary: false
    });
    const [address, setAddress] = useState({
        plotNumber: '',
        street: '',
        area: '',
        city: '',
        state: '',
        coordinates: {
            latitude: 0,
            longitude: 0
        }
    });
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [venueTypeOptions, setVenueTypeOptions] = useState([]);
    const [coordinates, setCoordinates] = useState({
        latitude: 0,
        longitude: 0
    });
    const [totalKeySlots, setTotalKeySlots] = useState(0);
    const [parkingSlots, setParkingSlots] = useState([{level: '', slots: 0}]);
    const allVenueTypes = useSelector((state) => state.venue.venueTypes);



    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let imageUrl = "";
        if (image !== null) {
            imageUrl = await uploadImage();
        }

        let _keySlots = [];
        let _parkingSlots = [];

        for (let x=0; x < totalKeySlots; x++) {
            _keySlots.push({title: `${x}`, number: x})
        }

        for (let x=0; x < parkingSlots.length; x++) {
            let _slots = [];
            for (let y=0; y < parkingSlots[x].slots; y++) {
                _slots.push({title: `${y}`, number: y})
            }
            _parkingSlots.push({title: parkingSlots[x].level, number: x, slots: _slots})
        }

        const data = {
            name,
            image: imageUrl,
            description,
            venueType,
            parkingPrice,
            address,
            slots: {
                key: _keySlots,
                parking: _parkingSlots
            }
        };

        await store.dispatch(addVenue(data));
        e.target.reset();
        setLoading(false);
    };

    const uploadImage = async () => {
        let formData = new FormData();
        formData.append("image", image);
        const urls = await upload(formData);
        return urls.length > 0 ? urls[0] : '';
    }

    useEffect(() => {
        let _venueTypes = [];
        for (let venueType of allVenueTypes) {
            if (!venueType.deleted) {
                _venueTypes.push({ value: venueType._id, label: venueType.title },)
            }
        }
        setVenueTypeOptions(_venueTypes);
    }, [allVenueTypes]);


    return (<>
        <div className="d-flex justify-content-center">
            <div className="card add-venue-card mt-10">
                <div style={{border: "0"}} className="card-header">
                    <h5 className="card-title text-center">Add Venue</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">

                            <div className="col-12">
                                <div className="d-flex align-items-center">
                                    <label className="col-4 avatar avatar-xxl profile-cover-avatar m-0" htmlFor="edit_img" style={{
                                        backgroundImage: imageUrl !== "" ? `url('${imageUrl}')` : "url(/assets/img/upload-venue-banner.png)",
                                        backgroundSize: imageUrl !== "" ? "cover" : "contain",
                                        backgroundRepeat: "no-repeat",
                                        borderRadius: "10px"
                                    }}>

                                        <input type="file" id="edit_img"  onChange={(e) => {
                                            setImage(e.target.files[0]);
                                            setImageUrl(URL.createObjectURL(e.target.files[0]));
                                        }}/>
                                        <span className="avatar-edit">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24"
                                             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             className="feather feather-edit-2 avatar-uploader-icon shadow-soft">
                                            <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                        </svg>
                                    </span>
                                    </label>

                                </div>
                            </div>

                            <div className="col-12 mt-3">
                                <input type="text" className="form-control w-100" id="name" placeholder="Name"
                                       onChange={(e) => setName(e.target.value)} required={true}
                                />
                                <div style={{position: "relative", bottom: "35px", left: "15px", width: "30px"}}>
                                    <svg width="20" height="22" viewBox="0 0 20 22" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13.71 11.71C14.6904 10.9387 15.406 9.88092 15.7572 8.68394C16.1085 7.48697 16.0779 6.21027 15.6698 5.03147C15.2617 3.85267 14.4963 2.83039 13.4801 2.10686C12.4639 1.38332 11.2474 0.994507 10 0.994507C8.75255 0.994507 7.53611 1.38332 6.51993 2.10686C5.50374 2.83039 4.73834 3.85267 4.33021 5.03147C3.92208 6.21027 3.89151 7.48697 4.24276 8.68394C4.59401 9.88092 5.3096 10.9387 6.29 11.71C4.61007 12.383 3.14428 13.4994 2.04889 14.9399C0.953495 16.3805 0.26956 18.0913 0.0699967 19.89C0.0555513 20.0213 0.0671132 20.1542 0.104022 20.2811C0.140931 20.4079 0.202464 20.5263 0.285108 20.6293C0.452016 20.8375 0.69478 20.9708 0.959997 21C1.22521 21.0292 1.49116 20.9518 1.69932 20.7849C1.90749 20.618 2.04082 20.3752 2.07 20.11C2.28958 18.1552 3.22168 16.3498 4.68822 15.0388C6.15475 13.7278 8.0529 13.003 10.02 13.003C11.9871 13.003 13.8852 13.7278 15.3518 15.0388C16.8183 16.3498 17.7504 18.1552 17.97 20.11C17.9972 20.3557 18.1144 20.5827 18.2991 20.747C18.4838 20.9114 18.7228 21.0015 18.97 21H19.08C19.3421 20.9698 19.5817 20.8373 19.7466 20.6313C19.9114 20.4252 19.9881 20.1624 19.96 19.9C19.7595 18.0962 19.0719 16.381 17.9708 14.9382C16.8698 13.4954 15.3969 12.3795 13.71 11.71ZM10 11C9.20887 11 8.43551 10.7654 7.77772 10.3259C7.11992 9.88636 6.60723 9.26164 6.30448 8.53074C6.00173 7.79983 5.92251 6.99557 6.07686 6.21964C6.2312 5.44372 6.61216 4.73099 7.17157 4.17158C7.73098 3.61217 8.44371 3.2312 9.21964 3.07686C9.99556 2.92252 10.7998 3.00173 11.5307 3.30448C12.2616 3.60724 12.8863 4.11993 13.3259 4.77772C13.7654 5.43552 14 6.20888 14 7C14 8.06087 13.5786 9.07828 12.8284 9.82843C12.0783 10.5786 11.0609 11 10 11Z"
                                            fill="#9079E9"/>
                                    </svg>
                                </div>
                            </div>

                            <div className="col-12">
                                <input type="text" className="form-control w-100"
                                       placeholder="Plot no." onChange={(e) => setAddress({
                                    ...address,
                                    plotNumber: e.target.value
                                })}
                                />
                                <div style={{position: "relative", bottom: "35px", left: "15px", width: "30px"}}>
                                    <img src="/assets/img/venue/zip-code.png" style={{width: '20px'}}/>

                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <input type="text" className="form-control w-100"
                                       placeholder="Pin No. / Street" onChange={(e) => setAddress({
                                    ...address,
                                    street: e.target.value
                                })}
                                />
                                <div style={{position: "relative", bottom: "35px", left: "15px", width: "30px"}}>
                                    <img src="/assets/img/venue/street.png" style={{width: '20px'}}/>

                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <input type="text" className="form-control w-100"
                                       placeholder="Area" onChange={(e) => setAddress({
                                    ...address,
                                    area: e.target.value
                                })}
                                />
                                <div style={{position: "relative", bottom: "35px", left: "15px", width: "30px"}}>
                                    <img src="/assets/img/venue/area.png" style={{width: '15px'}}/>

                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <input type="text" className="form-control w-100"
                                       placeholder="City" onChange={(e) => setAddress({
                                    ...address,
                                    city: e.target.value
                                })}
                                />
                                <div style={{position: "relative", bottom: "35px", left: "15px", width: "30px"}}>
                                    <img src="/assets/img/venue/city.png" style={{width: '20px'}}/>

                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <input type="text" className="form-control w-100"
                                       placeholder="State" onChange={(e) => setAddress({
                                    ...address,
                                    state: e.target.value
                                })}
                                />
                                <div style={{position: "relative", bottom: "35px", left: "15px", width: "30px"}}>
                                    <img src="/assets/img/venue/state.png" style={{width: '20px'}}/>

                                </div>
                            </div>

                            <div className="col-12 mb-3">
                                <MyMapComponent
                                    isMarkerShown={true}
                                    onClick={(e) => {
                                        console.log(e);
                                     setAddress({
                                         ...address,
                                         coordinates: {
                                             latitude: e.latLng.lat(),
                                             longitude: e.latLng.lng(),
                                         }
                                     })
                                    }}
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBCGmqQb0gn22x2RY-EaiEu1S1qwRavTOI&v=3.exp&libraries=geometry,drawing,places"
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `221px` }} />}
                                    mapElement={<div style={{ height: `100%`, borderRadius: '10px' }} />}
                                />
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <input type="number" className="form-control w-100"
                                       placeholder="Latitude" value={address.coordinates.latitude} onChange={(e) => setAddress({
                                    ...address,
                                    coordinates: {
                                        ...address.coordinates,
                                        latitude: e.target.value
                                    }
                                })}
                                />
                                <div style={{position: "relative", bottom: "35px", left: "15px", width: "30px"}}>
                                    <img src="/assets/img/venue/state.png" style={{width: '20px'}}/>

                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <input type="number" className="form-control w-100"
                                       placeholder="Longitude" value={address.coordinates.longitude} onChange={(e) => setAddress({
                                    ...address,
                                    coordinates: {
                                        ...address.coordinates,
                                        longitude: e.target.value
                                    }
                                })}
                                />
                                <div style={{position: "relative", bottom: "35px", left: "15px", width: "30px"}}>
                                    <img src="/assets/img/venue/state.png" style={{width: '20px'}}/>

                                </div>
                            </div>
                            <h4 className="col-12">Parking Slots</h4>
                            <div className="col-12 row mt-3" id="levels">
                                {parkingSlots.map((parkingSlot, key) => (
                                    <div className="col-12 row ms-2">
                                        <div className="col-12 p-0 m-0">
                                            <input type="text" className="form-control w-100" value={parkingSlot['level']}
                                                   placeholder="Level Name" onChange={(e) => {
                                                setParkingSlots([
                                                    ...parkingSlots.slice(0, key),
                                                    ...[{level: e.target.value, slots: parkingSlot.slots}],
                                                    ...parkingSlots.slice(key + 1, parkingSlots.length)
                                                ]);
                                            }}
                                            />
                                            <div style={{position: "relative", bottom: "35px", left: "15px", width: "30px"}}>
                                                <img src="/assets/img/venue/venue-name.png" style={{width: '20px'}}/>

                                            </div>
                                        </div>

                                        <div className="col-12 p-0 m-0">
                                            <input type="number" className="form-control w-100"
                                                   placeholder="Slots" value={parkingSlot['slots']}  onChange={(e) => {
                                                setParkingSlots([
                                                    ...parkingSlots.slice(0, key),
                                                    ...[{level: parkingSlot.level, slots: e.target.value}],
                                                    ...parkingSlots.slice(key + 1, parkingSlots.length)
                                                ]);
                                            }}
                                            />
                                            <div style={{position: "relative", bottom: "35px", left: "15px", width: "30px"}}>
                                                <img src="/assets/img/venue/slots.png" style={{width: '20px'}}/>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>

                                ))}

                            </div>
                            <div className="col-12 mb-3">
                                <button type="button" onClick={() => {
                                    setParkingSlots([
                                        ...parkingSlots,
                                        {level: '', slots: 0}
                                    ]);
                                }} className="btn btn-primary w-100">Add Level</button>
                            </div>



                            <h4 className="col-12">Key Slots</h4>
                            <div className="col-12">
                                <input type="number" className="form-control w-100"
                                       placeholder="Key Slots" onChange={(e) => setTotalKeySlots(e.target.value)}
                                />
                                <div style={{position: "relative", bottom: "35px", left: "15px", width: "30px"}}>
                                    <img src="/assets/img/venue/key-slots.png" style={{width: '20px'}}/>
                                </div>
                            </div>

                            <h4 className="col-12">Parking Price</h4>
                            <div className="complementary d-flex justify-content-between">
                                <p>Complementary</p>
                                <label className="switch">
                                    <input type="checkbox" checked={parkingPrice.complementary} onClick={() => {
                                        setParkingPrice({
                                            ...parkingPrice,
                                            complementary: !parkingPrice.complementary
                                        });
                                    }}/>
                                    <span className={`slider round ${parkingPrice.complementary ? 'checked' : ''}`}/>
                                </label>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <input type="number" className="form-control w-100"
                                       placeholder="Regular" onChange={(e) => setParkingPrice({
                                    ...parkingPrice,
                                    regular: parseFloat(e.target.value)
                                })}
                                />
                                <div style={{position: "relative", bottom: "35px", left: "15px", width: "30px"}}>
                                    <img src="/assets/img/venue/regular.png" style={{width: '20px'}}/>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <input type="number" className="form-control w-100"
                                       placeholder="Premium" onChange={(e) => setParkingPrice({
                                    ...parkingPrice,
                                    premium: parseFloat(e.target.value)
                                })}
                                />
                                <div style={{position: "relative", bottom: "35px", left: "15px", width: "30px"}}>
                                    <img src="/assets/img/venue/premium.png" style={{width: '20px'}}/>
                                </div>
                            </div>

                            <h4 className="col-12">Venue Type</h4>
                            <Select
                                name="venue-type"
                                className="select w-100 mb-3"
                                options={venueTypeOptions}
                                onChange={(e) => {
                                    setVenueType(e.value);
                                }}
                            />

                        </div>

                        <div className="create-button">
                            <Button loading={loading} type="submit" className="btn btn-primary w-100 me-2" disabled={loading}><i
                                className="fa fa-check-circle me-2"/>Submit</Button>
                        </div>
                    </form>

                </div>
            </div>
        </div>

    </>);
}

export default AddVenue;
