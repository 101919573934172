import React, { useState }  from 'react';
import { Link } from 'react-router-dom';
import store from '../../store';
import { login } from '../../store/auth/action';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faKey} from "@fortawesome/free-solid-svg-icons";

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const togglePassword = () => {
        setShowPassword(!showPassword);
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await store.dispatch(login(username, password));
        if (res) {
            window.location.href = "/";
        }
    }

    return <div className="main-wrapper login-body">
        <div className="login-wrapper">
            <div className="container">
                <img className="img-fluid logo-dark mb-2 p-5" src="/assets/img/iv_logo.png" alt="Logo" />
                <div className="loginbox">
                    <div className="login-right">
                        <div className="login-right-wrap">
                            <h1>Login</h1>
                            <p className="account-subtitle">Access to our dashboard</p>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label className="form-control-label">Email Address/Username</label>
                                    <div style={{ position: "relative" }} className="row form-group">
                                        <div className="col-12">
                                            <input type="text" className="form-control w-100" onChange={(e) => setUsername(e.target.value)}  required={true} />
                                            <div className="prefix-icon">
                                                <FontAwesomeIcon icon={faEnvelope} color="#9079E9" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-control-label">Password</label>
                                    <div className="pass-group">
                                        <div style={{ position: "relative" }} className="row form-group">
                                            <div className="col-12">
                                                <input type={showPassword ? "text" : "password"} className="form-control pass-input w-100" onChange={(e) => setPassword(e.target.value)}  required={true} />
                                                <div className="prefix-icon">
                                                    <FontAwesomeIcon icon={faKey} color="#9079E9" />
                                                </div>
                                            </div>
                                        </div>

                                        <span className={`toggle-password ${showPassword ? "fas fa-eye-slash" : "fas fa-eye"}`} onClick={() => togglePassword()}/>
                                    </div>
                                </div>
                                <button className="btn btn-lg btn-block btn-primary w-100" type="submit"><i
                                    className="fa fa-check-circle me-2"/>Login
                                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default Login;
