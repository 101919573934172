import axios from "axios";
import baseConfig from "../../Config";
import {
    addStaffFailed,
    permissionLoadedSuccess,
    staffDeleteFailed,
    staffEditFailed,
    staffEditSuccess,
    staffLoaded
} from "./reducer";
import {venueTypeDeleteFailed, venueTypeEditFailed} from "../venue/reducer";


export const addStaff = (data) => async (dispatch) => {
    let token = localStorage.getItem('token');
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        try {
            let res = await axios.post(
                `${baseConfig.apiBaseUrl}/admin/staff`,
                data,
                config
            );

            if (!res.data['status']) {
                dispatch({
                    type: addStaffFailed,
                    payload: res.data,
                });
            }
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: addStaffFailed,
                    payload: err.response.data,
                });
            }
        }

    }
}

export const getAllStaff = () => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        let page = 1;
        let limit = 100;
        while (page !== 0) {
            try {
                let res = await axios.get(
                    `${baseConfig.apiBaseUrl}/admin/staff/all?page=${page}&limit=${limit}`,
                    config
                );
                console.log(res.data);
                if (res.data['status']) {
                    if (res.data.data.length !== 0) {
                        dispatch({
                            type: staffLoaded,
                            payload: res.data,
                        });
                        page++;
                    } else {
                        page = 0;
                    }
                }
            } catch (err) {
                if (err.response) {
                    dispatch({
                        type: addStaffFailed,
                        payload: err.response.data,
                    });
                }
            }
        }

    }
}

export const getAllPermissions = () => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/staff/permissions`,
                config
            );
            if (res.data['status']) {
                dispatch({
                    type: permissionLoadedSuccess,
                    payload: res.data,
                });
            }
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: addStaffFailed,
                    payload: err.response.data,
                });
            }
        }

    }
}

export const deleteStaff = (id) => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.delete(
                `${baseConfig.apiBaseUrl}/admin/staff?id=${id}`,
                config
            );
            if (!res.data['status']) {
                dispatch({
                    type: staffDeleteFailed,
                    payload: res.data,
                });
            }
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: staffDeleteFailed,
                    payload: err.response.data,
                });
            }
        }
    }
}

export const changeStaffStatus = (id) => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.patch(
                `${baseConfig.apiBaseUrl}/admin/staff/change-status?id=${id}`,
                {},
                config
            );
            if (!res.data['status']) {
                dispatch({
                    type: staffEditFailed,
                    payload: res.data,
                });
            }
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: staffEditFailed,
                    payload: err.response.data,
                });
            }
        }
    }
}

export const editStaff = (id, data) => async (dispatch) => {
    let token = localStorage.getItem('token');
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        try {
            let res = await axios.patch(
                `${baseConfig.apiBaseUrl}/admin/staff?id=${id}`,
                data,
                config
            );

            if (!res.data['status']) {
                dispatch({
                    type: staffEditFailed,
                    payload: res.data,
                });
            }
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: staffEditFailed,
                    payload: err.response.data,
                });
            }
        }

    }
}
