import {useSelector} from "react-redux";
import store from "../store";
// import {logout} from "../store/auth/actions";
import {Link} from "react-router-dom";
import React, {useState} from 'react';
import {logout} from "../store/auth/action";
import $ from 'jquery';
import moment from "moment";
import {getAllNotifications, readAllNotification} from "../store/notification/action";
import {toggleNavOpen} from "../store/common/reducer";
import '../components/header.css';


const Header = () => {
    const common = useSelector((state) => state.common);

    const user = useSelector((state) => state.auth);
    const notifications = useSelector((state) => state.notification.notifications);

    return <>
        <div className="header header-one">

            <div className="header-left header-left-one">
                <Link to={'/'} className="logo">
                    <img src="/assets/img/iv_logo.png" alt="Logo" />
                </Link>
                <Link to={'/'} className="white-logo">
                    <img src="/assets/img/iv_logo.png" alt="Logo" />
                </Link>
                <Link to={'/'} className="logo logo-small">
                    <img src="/assets/img/iv_logo.png" alt="Logo" width="30" height="30" />
                </Link>
            </div>






            <a className="mobile_btn" id="mobile_btn" onClick={() => {
                store.dispatch({
                    type: toggleNavOpen,
                    payload: !common.mobileNavOpen,
                })
            }}>
                <i className="fas fa-bars"/>
            </a>


            <ul className="nav nav-tabs user-menu">
                <li className="nav-item dropdown">
                    <a href="#" className="dropdown-toggle nav-link notification-bell" data-bs-toggle="dropdown">
                    <svg style={{verticalAlign:"initial"}} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.0001 9.7041V9C19.0001 5.13401 15.8661 2 12.0001 2C8.13407 2 5.00006 5.13401 5.00006 9V9.7041C5.00006 10.5491 4.74995 11.3752 4.28123 12.0783L3.13263 13.8012C2.08349 15.3749 2.88442 17.5139 4.70913 18.0116C9.48258 19.3134 14.5175 19.3134 19.291 18.0116C21.1157 17.5139 21.9166 15.3749 20.8675 13.8012L19.7189 12.0783C19.2502 11.3752 19.0001 10.5491 19.0001 9.7041Z" stroke="currentColor" stroke-width="1.5"></path>
                                            <path d="M7.5 19C8.15503 20.7478 9.92246 22 12 22C14.0775 22 15.845 20.7478 16.5 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path>
                                            <path d="M12 6V10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path>
                                        </svg>
                        <span className=" rounded-pill badge">{notifications.filter((notification) => !notification.read).length}</span>
                    </a>
                    <div className="dropdown-menu notifications">
                        <div className="topnav-dropdown-header">
                            <span className="notification-title">Unread Notifications</span>
                            <p className="clear-noti" style={{'cursor': 'pointer'}} onClick={async () => {
                                await readAllNotification();
                                store.dispatch(getAllNotifications());
                            }}>Read All</p>
                        </div>
                        <div className="noti-content">
                            <ul className="notification-list">
                            {notifications.filter((notification) => !notification.read).map((notification, index) => {
                                const duration = moment.duration(moment().diff(moment(notification.createdAt)));
                                const hours = parseInt(duration.asHours().toString());
                                const minutes = parseInt(duration.asMinutes().toString());
                                return <li className="notification-message">
                                        <Link to={`/parking/${notification.payload.ticket}`}>
                                            <div className="media d-flex">
                                                <div className="media-body">
                                                    <p className="noti-details"><span
                                                        className="noti-title badge badge-primary text-white">{notification.payload.ticket}</span> {notification.message}</p>
                                                    <p className="noti-time"><span
                                                        className="notification-time">{hours > 24 ? moment(notification.createdAt).toString() : minutes > 60 ? `${hours} hours ago` : `${minutes} min ago` }</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>

                            })}
                            </ul>
                        </div>
                        <div style={{margin:"10px 0"}} className="topnav-dropdown-footer">
                            <Link style={{marginTop:"4px", width:"66%", marginLeft:"18%"}} className="btn btn-primary text-light" to='/notifications'>View all Notifications</Link>
                        </div>
                    </div>
                </li>
                <li className="nav-item dropdown has-arrow main-drop">
                    <a href="#" className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                        <span className="user-img">
                            {/* <img src={`${user.profilePic ? user.profilePic : `https://ui-avatars.com/api/?background=1b6ca8&color=fff&name=${user.name}&size=40&rounded=true`}`} alt="" /> */}
                            <img src={`${user.profilePic ? user.profilePic : `/assets/img/iv_logo.png`}`} alt="" />
                            <span className="status online"/>
                        </span>
                        <span>{user.name}</span>
                    </a>
                    <div className="dropdown-menu">
                        <Link className="dropdown-item" to={'/edit-profile'}><i data-feather="user" className="me-1"/>
                            Edit Profile</Link>
                        <Link className="dropdown-item" to={'/login'} onClick={() =>  store.dispatch(logout())}><i data-feather="log-out" className="me-1"/>
                            Logout</Link>
                    </div>
                </li>

            </ul>

        </div>
    </>;
}

export default Header;
