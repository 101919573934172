import React, {useEffect, useState} from 'react';
import {upload} from "../../store/utility/action";
import Button from 'react-bootstrap-button-loader';
import store from "../../store";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import Select from 'react-select';
import {addStaff, editStaff} from "../../store/staff/action";
import moment from "moment";


const ParkingDetails = () => {
    const params = useParams();
    const allParking = useSelector((state) => state.parking.parking);
    const allStaff = useSelector((state) => state.staff.admins);
    const allVenue = useSelector((state) => state.venue.venues);
    const [parking, setParking] = useState({
        ticket: '',
        guest: {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: ''
        },
        car: {
            licensePlate: '',
            image: ''
        },
        remarks: '',
        price: {
            rate: 'Default',
            amount: 0,
            paymentMethod: '',
            paymentStatus: ''
        },
        status: '',
        timings: {
            arrival: null,
            checkin: null,
            parked: null
        },
        venue: {
            id: '',
            slots: {
                parking: {
                    level: {
                        id: '',
                        title: ''
                    },
                    slot: {
                        id: '',
                        title: ''
                    }
                },
                key: {
                    id: '',
                    title: ''
                }
            }
        },
        location: {
            checkIn: {
                latitude: 0,
                longitude: 0
            },
            parking: {
                latitude: 0,
                longitude: 0
            },
        },
        valet: '',
        deleted: false,
        createdAt: Date.now().toString(),
        updatedAt: Date.now().toString(),
    });
    const [venue, setVenue] = useState(null);
    const [valet, setValet] = useState(null);

    useEffect(() => {
        let venue, valet;
        let _parking = allParking.find((p) => p._id === params.ticket || p.ticket === params.ticket);
        if (_parking) {
            venue = allVenue.find((v) => v._id === _parking.venue.id);
            valet = allStaff.find((s) => s._id === _parking.valet);
            setVenue(venue);
            setValet(valet);
            setParking(_parking);
        }
    }, [params, allParking])

    return (<>
        {parking != null && <>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Parking #{parking.ticket}</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="ticket" className="form-label">Ticket</label>
                            <input type="text" className="form-control w-100" id="ticket" name="ticket" placeholder="Ticket" value={`#${parking.ticket}`} disabled={true}/>
                        </div>

                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="status" className="form-label">Status</label>
                            <input type="text" className="form-control w-100" id="status" name="status" placeholder="Status" value={parking.status} disabled={true}/>
                        </div>

                        {valet != null && <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="valet" className="form-label">Valet Email</label>
                            <input type="text" className="form-control w-100" id="valet" name="valet"
                                   placeholder="Valet" value={valet.email} disabled={true}/>
                        </div>}

                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="remarks" className="form-label">Remarks</label>
                            <input type="text" className="form-control w-100" id="remarks" name="remarks" placeholder="Remarks" value={parking.remarks} disabled={true}/>
                        </div>
                    </div>

                </div>
            </div>
            <div className="card mt-3">
                <div className="card-header">
                    <h5 className="card-title">Timings</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="arrival" className="form-label">Arrival</label>
                            <input type="text" className="form-control w-100" id="arrival" name="arrival" placeholder="Arrival" value={parking.timings != null ? moment(parking.timings.arrival || "").format('MMMM Do YYYY, h:mm:ss a') : ''} disabled={true}/>
                        </div>

                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="check-in" className="form-label">Check-In</label>
                            <input type="text" className="form-control w-100" id="check-in" name="check-in" placeholder="Check In" value={parking.timings != null ? moment(parking.timings.checkin || "").format('MMMM Do YYYY, h:mm:ss a') : ''} disabled={true}/>
                        </div>

                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="parked" className="form-label">Parked</label>
                            <input type="text" className="form-control w-100" id="parked" name="parked" placeholder="Parked" value={parking.timings != null ? moment(parking.timings.parked || "").format('MMMM Do YYYY, h:mm:ss a') : ''} disabled={true}/>
                        </div>
                    </div>

                </div>
            </div>
            <div className="card mt-3">
                <div className="card-header">
                    <h5 className="card-title">Guest</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="firstName" className="form-label">First Name</label>
                            <input type="text" className="form-control w-100" id="firstName" name="firstName" placeholder="First Name" value={parking.guest.firstName || ""} disabled={true}/>
                        </div>

                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="lastName" className="form-label">Last Name</label>
                            <input type="text" className="form-control w-100" id="lastName" name="lastName" placeholder="Last Name" value={parking.guest.lastName || ""} disabled={true}/>
                        </div>

                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="phone" className="form-label">Phone Number</label>
                            <input type="text" className="form-control w-100" id="phone" name="phone" placeholder="Phone Number" value={parking.guest.phoneNumber || ""} disabled={true}/>
                        </div>

                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="text" className="form-control w-100" id="email" name="email" placeholder="Email Address" value={parking.guest.email || ""} disabled={true}/>
                        </div>
                    </div>

                </div>
            </div>
            <div className="card mt-3">
                <div className="card-header">
                    <h5 className="card-title">Car</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="image" className="form-label">Image</label>
                            <img src={parking.car.image || ""} style={{width: '100%'}} />
                        </div>
                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="license" className="form-label">License Tag</label>
                            <input type="text" className="form-control w-100" id="license" name="license" placeholder="License Tag" value={parking.car.licensePlate || ""} disabled={true}/>
                        </div>
                    </div>

                </div>
            </div>
            <div className="card mt-3">
                <div className="card-header">
                    <h5 className="card-title">Price</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="type" className="form-label">Rate Type</label>
                            <input type="text" className="form-control w-100" id="type" name="type" placeholder="Rate Type" value={parking.price.rate || ""} disabled={true}/>
                        </div>

                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="amount" className="form-label">Amount ($)</label>
                            <input type="text" className="form-control w-100" id="amount" name="amount" placeholder="Amount" value={parking.price.amount || ""} disabled={true}/>
                        </div>
                    </div>

                </div>
            </div>
            {(parking.venue && venue != null) && <div className="card mt-3">
                <div className="card-header">
                    <h5 className="card-title">Venue</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="venue" className="form-label">Venue</label>
                            <input type="text" className="form-control w-100" id="venue" name="venue" placeholder="Venue"
                                   value={venue.name} disabled={true}/>
                        </div>
                        {parking.venue.slots && <>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <label htmlFor="level" className="form-label">Level</label>
                                <input type="text" className="form-control w-100" id="level" name="level" placeholder="Level"
                                       value={parking.venue.slots.parking.level.title || ""} disabled={true}/>
                            </div>

                            <div className="mb-3 col-md-6 col-sm-12">
                                <label htmlFor="slot" className="form-label">Slot</label>
                                <input type="text" className="form-control w-100" id="slot" name="slot" placeholder="Slot"
                                       value={parking.venue.slots.parking.slot.title || ""} disabled={true}/>
                            </div>

                            <div className="mb-3 col-md-6 col-sm-12">
                                <label htmlFor="keySlot" className="form-label">Key Slot</label>
                                <input type="text" className="form-control w-100" id="keySlot" name="keySlot"
                                       placeholder="Key Slot" value={parking.venue.slots.key.title || ""} disabled={true}/>
                            </div>
                        </>}
                    </div>

                </div>
            </div>}
        </>}
    </>);
}

export default ParkingDetails;
