import React, { useState } from 'react';
import { upload } from '../../store/utility/action';
import Button from 'react-bootstrap-button-loader';
import store from '../../store';
import { addConfig } from '../../store/config/action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faT, faFileText, faImage } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { toast } from "react-toastify";


const AddConfig = () => {
    const [title, setTitle] = useState('');
    const [value, setValue] = useState('');
    const [type, setType] = useState('string');
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState("");
    const configTypes = [
        { value: 'image', label: 'Image' },
        { value: 'number', label: 'Number' },
        { value: 'text', label: 'Text' },
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (type === 'image' && image == null) {
            setLoading(false);
            toast.error("Please select image", {});
            return;
        }
        let content = value;
        if (image !== null) {
            content = await uploadFile(image);
        }
        const data = {
            title,
            value: content,
            type,
        };
        await store.dispatch(addConfig(data));
        e.target.reset();
        setType('');
        setTitle('');
        setValue('');
        setImage(null);
        setImageUrl('');
        setLoading(false);
    };

    const uploadFile = async (file) => {
        let formData = new FormData();
        formData.append("image", image);
        const urls = await upload(formData);
        console.log(urls);
        return urls.length > 0 ? urls[0] : '';
    }

    return (
        <>
            <div className="d-flex justify-content-center">
                <div className="card add-venue-card">
                    <div style={{ border: '0' }} className="card-header">
                        <h5 className="card-title text-center">Add setting</h5>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="type" className="form-label">
                                    Type <span className="text-danger">(required)</span>
                                </label>
                                <div
                                    style={{ position: 'relative' }}
                                    className="row form-group mb-3"
                                >
                                    <div className="col-sm-12">
                                        <Select
                                            name="config-type"
                                            className="select w-100 mb-3"
                                            options={configTypes}
                                            onChange={(e) => {
                                                setType(e.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">
                                    Title <span className="text-danger">(required)</span>
                                </label>
                                <div
                                    style={{ position: 'relative' }}
                                    className="row form-group mb-3"
                                >
                                    <div className="col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control w-100"
                                            id="title"
                                            placeholder="title"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            required
                                        />
                                        <div className="prefix-icon">
                                            <FontAwesomeIcon icon={faT} color="#9079E9" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {type === 'image' || type === 'video' || type === 'document' ? <>
                                <div className="col-12">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 avatar avatar-xxl profile-cover-avatar m-0" htmlFor="edit_img" style={{
                                            backgroundImage: imageUrl !== "" ? `url('${imageUrl}')` : "url(/assets/img/upload-venue-banner.png)",
                                            backgroundSize: imageUrl !== "" ? "cover" : "contain",
                                            backgroundRepeat: "no-repeat",
                                            borderRadius: "10px"
                                        }}>

                                            <input type="file" id="edit_img" onChange={(e) => {
                                                setImage(e.target.files[0]);
                                                setImageUrl(URL.createObjectURL(e.target.files[0]));
                                            }} />
                                            <span className="avatar-edit">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    className="feather feather-edit-2 avatar-uploader-icon shadow-soft">
                                                    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                                </svg>
                                            </span>
                                        </label>

                                    </div>
                                </div></> : <>
                                <div className="mb-3">
                                    <label htmlFor="value" className="form-label">
                                        Value <span className="text-danger">(required)</span>
                                    </label>
                                    <div
                                        style={{ position: 'relative' }}
                                        className="row form-group mb-3"
                                    >
                                        <div className="col-sm-12">
                                            <input
                                                type={type}
                                                className="form-control w-100"
                                                id="value"
                                                placeholder="Value"
                                                value={value}
                                                onChange={(e) => setValue(e.target.value)}
                                                required
                                            />
                                            <div className="prefix-icon">
                                                <FontAwesomeIcon icon={faFileText} color="#9079E9" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}
                            <div className="d-flex justify-content-center">
                                <Button loading={loading} type="submit" className="btn btn-primary me-2 w-75" disabled={loading}><i
                                    className="fa fa-check-circle me-2" />Submit</Button>
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </>);
}

export default AddConfig;

