import React, {useEffect, useState} from 'react';
import {upload} from "../../store/utility/action";
import Button from 'react-bootstrap-button-loader';
import store from "../../store";
import {useNavigate, useParams} from "react-router";
import {useSelector} from "react-redux";
import Select from 'react-select';
import {addStaff, editStaff} from "../../store/staff/action";
import moment from "moment";
import {assignValetToRequest} from "../../store/parking/action";


const RequestDetails = () => {
    const params = useParams();
    const navigate = useNavigate();
    const allRequests = useSelector((state) => state.parking.request);
    const allParking = useSelector((state) => state.parking.parking);
    const allStaff = useSelector((state) => state.staff.admins);
    const allVenue = useSelector((state) => state.venue.venues);
    const [request, setRequest] = useState(null);
    const [parking, setParking] = useState(null);
    const [venue, setVenue] = useState(null);
    const [valet, setValet] = useState(null);
    const [staff, setStaff] = useState([]);

    useEffect(() => {
        let venue, valet, parking;
        let _request = allRequests.find((p) => p._id === params.ticket);
        if (_request) {
            venue = allVenue.find((v) => v._id === _request.venue);
            valet = allStaff.find((s) => s._id === _request.valet);
            parking = allParking.find((p) => p.ticket === _request.ticket);
            setVenue(venue);
            setValet(valet);
            setParking(parking);
            setRequest(_request);
        }
    }, [params, allRequests])

    useEffect(() => {
        if (venue != null) {
            let _staff = [];
            for (let s of allStaff) {
                if (s.type === 'Valet' && s.venues.includes(venue._id)) {
                    _staff.push({value: s._id, label: `${s.name} (${s.available ? "AVAILABLE" : "NOT AVAILABLE"})`},)
                }
            }
            setStaff(_staff);
        }
    }, [allStaff, venue]);

    return (<>
        {request != null && parking != null && <>
            <div className={"d-flex justify-content-end w-100 mb-5"}>
                <button className={"btn btn-primary"} onClick={() => {
                    navigate(`/parking/${parking._id}`);
                }}>Parking Details</button>
            </div>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Request #{request.ticket}</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="ticket" className="form-label">Ticket</label>
                            <input type="text" className="form-control w-100" id="ticket" name="ticket" placeholder="Ticket" value={`#${request.ticket}`} disabled={true}/>
                        </div>

                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="status" className="form-label">Status</label>
                            <input type="text" className="form-control w-100" id="status" name="status" placeholder="Status" value={request.status} disabled={true}/>
                        </div>

                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="valet" className="form-label">Valet Email</label>
                            <input type="text" className="form-control w-100" id="valet" name="valet" placeholder="Valet" value={valet != null ? valet.email : 'NOT ASSIGNED'} disabled={true}/>
                        </div>
                        {request.status !== 'Delivered' && <div className="mb-3 col-6">
                            <label htmlFor="venue-type" className="form-label">Change Valet</label>
                            <Select
                                name="staff"
                                className="w-100"
                                options={staff}
                                onChange={(e) => {
                                    store.dispatch(assignValetToRequest(request._id, e.value));
                                }}
                            />
                        </div>}

                        <div className="mb-3 col-12">
                            <label htmlFor="remarks" className="form-label">Remarks</label>
                            <input type="text" className="form-control w-100" id="remarks" name="remarks" placeholder="Remarks" value={request.remarks} disabled={true}/>
                        </div>
                    </div>

                </div>
            </div>

            <div className="card mt-3">
                <div className="card-header">
                    <h5 className="card-title">Payment Details</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="payment_method" className="form-label">Payment Method</label>
                            <input type="text" className="form-control w-100" id="payment_method" name="payment_method" placeholder="Payment Method" value={`${request.payment.method}`} disabled={true}/>
                        </div>

                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="payment_status" className="form-label">Status</label>
                            <input type="text" className="form-control w-100" id="payment_status" name="payment_status" placeholder="Payment Status" value={request.payment.status} disabled={true}/>
                        </div>

                        <div className="mb-3  col-md-6 col-sm-12">
                            <label htmlFor="cost" className="form-label">Total Cost</label>
                            <input type="text" className="form-control w-100" id="cost" name="cost" placeholder="Total Cost" value={`\$ ${parking.price.amount}`} disabled={true}/>
                        </div>
                    </div>

                </div>
            </div>
        </>}
    </>);
}

export default RequestDetails;
