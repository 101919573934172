import React, {useEffect, useState} from 'react';
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import 'moment-countdown';
import {useSelector} from "react-redux";


const ParkingDetailCard = ({ticket, onClose}) => {
    console.log(ticket)
    const allParking = useSelector((state) => state.parking.parking);
    const allStaff = useSelector((state) => state.staff.admins);
    const allVenue = useSelector((state) => state.venue.venues);
    const [parking, setParking] = useState(null);
    const [checkInTimer, setCheckInTimer] = useState('');

    useEffect(() => {
        if (ticket) {
            let _parking = allParking.find((p) => p.ticket === ticket);
            let venue = allVenue.find((v) => v._id === _parking.venue.id);
            let valet = allStaff.find((s) => s._id === _parking.valet);
            setParking({
                ..._parking,
                venueDetails: venue,
                valetDetails: valet,
            })
            let interval = setInterval(function(){
                let duration = moment.duration(moment.now() - moment(_parking['timings']['arrival']), 'milliseconds');
                setCheckInTimer(duration.days() + ":" + duration.hours() + ":" + duration.minutes() + ":" + duration.seconds());
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [ticket, allStaff, allVenue])

    return (
        <>
            {parking !== null && <div className="parking-detail-card row">
                <div className="col-12 d-flex justify-content-end mb-3">
                    <img src='/assets/img/close-popup.png' style={{ width: "20px", cursor: "pointer" }} onClick={onClose} />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 p-0 m-0">
                    <div className="car-image p-0 m-0" style={{backgroundImage: `url('${parking['car']['image'] !== '' ? parking['car']['image'] : ''}')`, backgroundSize: 'cover'}} />
                    <br />
                    <div className="card parking-details row p-0 m-0">
                        <div className="col-12 d-flex justify-content-between detail-item">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="tag"/></div>
                                <p className="item-title">Ticket</p>
                            </div>
                            <div className="item-value">#{parking['ticket']}</div>
                        </div>
                        <div className="col-12 d-flex justify-content-between detail-item">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="bar-chart"/></div>
                                <p className="item-title">Level</p>
                            </div>
                            <div className="item-value">{parking['venue']['slots'] !== undefined && parking['venue']['slots']['parking']['level']['title']}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-between detail-item">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="map-pin"/></div>
                                <p className="item-title">Spot</p>
                            </div>
                            <div className="item-value">{parking['venue']['slots'] !== undefined && parking['venue']['slots']['parking']['slot']['title']}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-between detail-item">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="key"/></div>
                                <p className="item-title">Key Slot</p>
                            </div>
                            <div className="item-value">{parking['venue']['slots'] !== undefined && parking['venue']['slots']['key']['title']}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-between detail-item">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="command"/></div>
                                <p className="item-title">Parking Type</p>
                            </div>
                            <div className="item-value">{parking['price']['rate']}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-between detail-item">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="credit-card"/></div>
                                <p className="item-title">Amount</p>
                            </div>
                            <div className="item-value">${parking['price']['amount']}</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="d-flex justify-content-end">
                        <div className="timing">
                            {parking['status'] === "Parked" ? <p>{moment(parking['timings']['parked']).diff(moment(parking['timings']['arrival']), 'hours')}:{moment(parking['timings']['parked']).diff(moment(parking['timings']['arrival']), 'minutes')}:{moment(parking['timings']['parked']).diff(moment(parking['timings']['arrival']), 'seconds') % 60}</p> : <p>{checkInTimer}</p>}
                        </div>
                    </div>
                    <div className="card parking-details row p-0 m-0">
                        <div className="col-12 d-flex justify-content-start detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="user"/></div>
                            </div>
                            <div className="item-value user-details">{parking['guest']['firstName']} {parking['guest']['lastName']}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-start detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="hash"/></div>
                            </div>
                            <div className="item-value user-details">{parking['car']['licensePlate']}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-start detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="mail"/></div>
                            </div>
                            <div className="item-value user-details w-100">{parking['guest']['email']}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-start detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <div className="icon-container d-flex justify-content-center align-items-center"><FeatherIcon icon="phone"/></div>
                            </div>
                            <div className="item-value user-details">{parking['guest']['phoneNumber']}</div>
                        </div>

                    </div>
                    <br /><br />
                    <div className="card parking-details row p-0 m-0">
                        <div className="col-12 d-flex justify-content-between detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <p className="item-title">Valet Name</p>
                            </div>
                            <div className="item-value">{parking['valetDetails']['name']}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-between detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <p className="item-title">Valet Email</p>
                            </div>
                            <div className="item-value">{parking['valetDetails']['email']}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-between detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <p className="item-title">Valet No.</p>
                            </div>
                            <div className="item-value">{parking['valetDetails']['phoneNumber']}</div>
                        </div>
                    </div>
                    <br /><br />
                    <div className="card parking-details row p-0 m-0">
                        <div className="col-12 d-flex justify-content-between detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <p className="item-title">Arrival Time</p>
                            </div>
                            <div className="item-value">{parking['timings']['arrival'] && moment(parking['timings']['arrival']).format('DD/MM/YY hh:mm:ss')}</div>
                        </div>
                        <div className="col-12 d-flex justify-content-between detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <p className="item-title">Check-In Time</p>
                            </div>
                            <div className="item-value">{parking['timings']['checkin'] && moment(parking['timings']['checkin']).format('DD/MM/YY hh:mm:ss')}</div>
                        </div>
                        <div className="col-12 d-flex justify-content-between detail-item w-100">
                            <div className="item d-flex align-items-center">
                                <p className="item-title">Parked Time</p>
                            </div>
                            <div className="item-value">{parking['timings']['parked'] && moment(parking['timings']['checkin']).format('DD/MM/YY hh:mm:ss')}</div>
                        </div>
                    </div>
                </div>
            </div>}</>
    );
}

export default ParkingDetailCard;
