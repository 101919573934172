import React, {useEffect} from 'react';
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import {useSelector} from "react-redux";

const Base = ({children}) => {
    const common = useSelector((state) => state.common);


    const loadScript = (src) => {
        return new Promise(function (resolve, reject) {
            let script = document.createElement('script')
            script.src = src
            script.addEventListener('load', function () {
                resolve()
            })
            script.addEventListener('error', function (e) {
                reject(e)
            })
            document.body.appendChild(script)
            document.body.removeChild(script)
        })

    }

    useEffect(() => {
        setTimeout(function () {
            loadScript(`${process.env.PUBLIC_URL}assets/vendors/core/core.js`);
            loadScript(`${process.env.PUBLIC_URL}assets/vendors/feather-icons/feather.min.js`);
            loadScript(`${process.env.PUBLIC_URL}assets/js/template.js`);
            console.log("SCRIPT ADDED")
        }, 500);

    })


    return (
        <div className={`main-wrapper ${common.mobileNavOpen ? 'slide-nav' : ''}`}>
            <Header />
            <Sidebar />
            <div className="page-wrapper">
                <div className="content container-fluid h-100 align-middle">
                    {children}
                </div>
            </div>
        </div>

    );
}

export default Base;
