import React, {useState} from 'react';
import {upload} from "../../store/utility/action";
import Button from 'react-bootstrap-button-loader';
import store from "../../store";
import {addVenueType} from "../../store/venue/action";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faT, faFileText, faImage} from "@fortawesome/free-solid-svg-icons";



const AddVenueType = () => {

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let imageUrl = "";
        console.log(image);
        if (image !== null) {
            imageUrl = await uploadImage();
        }
        const data = {
            title,
            image: imageUrl,
            description
        };

        await store.dispatch(addVenueType(data));
        e.target.reset();
        setLoading(false);
    };

    const uploadImage = async () => {
        let formData = new FormData();
        formData.append("image", image);
        const urls = await upload(formData);
        return urls.length > 0 ? urls[0] : '';
    }


    return (<>
        <div className='d-flex justify-content-center'>
            <div className="card add-venue-type-card">
                <div style={{border: "0"}} className="card-header">
                    <h5 className="card-title text-center">Add venue type</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="title" className="form-label">Title <span
                                className='text-danger'>(required)</span></label>
                            <div style={{ position: "relative" }} className="row form-group mb-3">
                                <div className="col-sm-12">
                                    <input type="text" className="form-control w-100" id="title" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} required
                                    />
                                    <div className="prefix-icon">
                                        <FontAwesomeIcon icon={faT} color="#9079E9" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="description" className="form-label">Description</label>
                            <div style={{ position: "relative" }} className="row form-group mb-3">
                                <div className="col-sm-12">
                                    <textarea className="form-control w-100" id="description" rows="5"
                                              onChange={(e) => setDescription(e.target.value)}></textarea>
                                    <div className="prefix-icon">
                                        <FontAwesomeIcon icon={faFileText} color="#9079E9" />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="mb-3">
                            <label htmlFor="image" className="form-label">Image</label>

                            <div style={{ position: "relative" }} className="row form-group mb-3">
                                <div className="col-sm-12">
                                    <input type="file" className="form-control w-100" id="image" name="image"
                                           onChange={(e) => setImage(e.target.files[0])}/>
                                    <div className="prefix-icon">
                                        <FontAwesomeIcon icon={faImage} color="#9079E9" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <Button loading={loading} type="submit" className="btn btn-primary me-2 w-75" disabled={loading}><i
    className="fa fa-check-circle me-2"/>Submit</Button>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    </>);
}

export default AddVenueType;
