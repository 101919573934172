import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  getCustomDailyRevenue, getCustomMonthlyRevenue, getCustomYearlyRevenue,
  getDailyParkingsAndRequests,
  getDashboardCardStats,
  getMonthlyParkingsAndRequests,
  getMonthlyRevenueAnalytics,
  getParkingSlotDistribution,
  getRegisteredVenue, getRevenueComparisonAnalytics,
  getVenueTypeDistribution,
  getWeeklyParkingsAndRequests,
  getWeeklyRevenueAnalytics,
  getYearlyRevenueAnalytics,
} from "../store/dashboard/action";
import Chart from "react-apexcharts";
import io from "socket.io-client";
import config from "../Config";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import DateTimePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const Dashboard = () => {
  const [onlineRevenue, setOnlineRevenue] = useState(0);
  const [offlinePaidRevenue, setOfflinePaidRevenue] = useState(0);
  const [offlinePendingRevenue, setOfflinePendingRevenue] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [cardStats, setCardStats] = useState({
    revenue: {
      currentWeek: 0,
      previousWeek: 0,
      percentDifference: 0,
    },
    parking: {
      currentWeek: 0,
      previousWeek: 0,
      percentDifference: 0,
    },
    valets: {
      currentWeek: 0,
      previousWeek: 0,
      percentDifference: 0,
    },
    venues: {
      currentWeek: 0,
      previousWeek: 0,
      percentDifference: 0,
    },
    parked: {
      currentWeek: 0,
      previousWeek: 0,
      percentDifference: 0,
    },
    delivered: {
      currentWeek: 0,
      previousWeek: 0,
      percentDifference: 0,
    },
    onlineRevenueData: [],
    offlineRevenueData: [],
  });
  const [parkingSlotDistribution, setParkingSlotDistribution] = useState({
    xAxis: [],
    yAxis: [],
  });

  const [venueCount, setVenueCount] = useState({
    xAxis: [],
    yAxis: [],
  });

  const [venueTypeCount, setVenueTypeCount] = useState({
    xAxis: [],
    yAxis: [],
  });
  const [revenueRange1, setRevenueRange1] = useState([new Date(), new Date()]);
  const [revenueRange2, setRevenueRange2] = useState([new Date(), new Date()]);
  const [revenueComparisonData, setRevenueComparisonData] = useState({
    range1: [],
    range2: [],
  });


  const socket = io(config.socketBaseUrl);
  socket.on("parking", (arg) => {
    getCardStats();
    getSlotDistribution();
    switch (revenueAnalyticsType) {
      case "monthly":
        getMonthlyRevenue();
        break;
      case "weekly":
        getWeeklyRevenue();
        break;
      case "yearly":
        getYearlyRevenue();
        break;
      default:
        getMonthlyRevenue();
    }
  });

  const [revenueAnalyticsType, setRevenueAnalyticsType] = useState("monthly");
  const [revenueAnalytics, setRevenueAnalytics] = useState({
    paid: {
      xAxis: [],
      yAxis: [],
    },
    unpaid: {
      xAxis: [],
      yAxis: [],
    },
  });

  const [parkingAnalyticsType, setParkingAnalyticsType] = useState("monthly");
  const [parkingAnalytics, setParkingAnalytics] = useState({
    parking: {
      xAxis: [],
      yAxis: [],
    },
    requests: {
      xAxis: [],
      yAxis: [],
    },
  });

  // Custom Date Revenue
  const [customDailyRevenueComparison, setCustomDailyRevenueComparison] = useState({
    day1: {
      xAxis: [],
      yAxis: [],
    },
    day2: {
      xAxis: [],
      yAxis: [],
    },
  });
  const [customMonthlyRevenueComparison, setCustomMonthlyRevenueComparison] = useState({
    month1: {
      xAxis: [],
      yAxis: [],
    },
    month2: {
      xAxis: [],
      yAxis: [],
    },
  });
  const [customYearlyRevenue, setCustomYearlyRevenue] = useState(
      {
        year1: null,
        year2: null,
      }
  );
  const [customYearlyRevenueComparison, setCustomYearlyRevenueComparison] = useState({
    year1: {
      xAxis: [],
      yAxis: [],
    },
    year2: {
      xAxis: [],
      yAxis: [],
    },
  });

  const getCardStats = async () => {
    const stats = await getDashboardCardStats();
    setCardStats(stats);
  };

  const getSlotDistribution = async () => {
    const distribution = await getParkingSlotDistribution();
    setParkingSlotDistribution({
      ...distribution,
    });
  };

  const getMonthlyRevenue = async () => {
    const stats = await getMonthlyRevenueAnalytics();
    console.log(stats);
    setRevenueAnalytics({
      ...stats,
    });
  };

  const getWeeklyRevenue = async () => {
    const stats = await getWeeklyRevenueAnalytics();
    setRevenueAnalytics({
      ...stats,
    });
  };

  const getYearlyRevenue = async () => {
    const stats = await getYearlyRevenueAnalytics();
    setRevenueAnalytics({
      ...stats,
    });
  };

  const getVenueCount = async () => {
    const stats = await getRegisteredVenue();
    setVenueCount({
      ...stats,
    });
  };

  const getMonthlyParkingAnalytics = async () => {
    const stats = await getMonthlyParkingsAndRequests();
    setParkingAnalytics({
      ...stats,
    });
  };

  const getWeeklyParkingAnalytics = async () => {
    const stats = await getWeeklyParkingsAndRequests();
    setParkingAnalytics({
      ...stats,
    });
  };

  const getDailyParkingAnalytics = async () => {
    const stats = await getDailyParkingsAndRequests();
    setParkingAnalytics({
      ...stats,
    });
  };

  const getVenueTypeCount = async () => {
    const stats = await getVenueTypeDistribution();
    setVenueTypeCount({
      ...stats,
    });
  };

  useEffect(() => {
    getCardStats();
    getVenueCount();
    getSlotDistribution();
    getVenueTypeCount();
  }, []);

  useEffect(() => {
    switch (revenueAnalyticsType) {
      case "monthly":
        getMonthlyRevenue();
        break;
      case "weekly":
        getWeeklyRevenue();
        break;
      case "yearly":
        getYearlyRevenue();
        break;
      default:
        getMonthlyRevenue();
    }
  }, [revenueAnalyticsType]);

  useEffect(() => {
    switch (parkingAnalyticsType) {
      case "monthly":
        getMonthlyParkingAnalytics();
        break;
      case "weekly":
        getWeeklyParkingAnalytics();
        break;
      case "daily":
        getDailyParkingAnalytics();
        break;
      default:
        getMonthlyParkingAnalytics();
    }
  }, [parkingAnalyticsType]);



  useEffect(() => {
    let total = 0;
    if (
      cardStats &&
      cardStats.onlineRevenueData &&
      cardStats.onlineRevenueData.length
    ) {
      let sum = 0;
      cardStats.onlineRevenueData.forEach((parking) => {
        sum += parking.price.amount;
      });
      setOnlineRevenue(sum);
      total += sum;
    }
    if (
      cardStats &&
      cardStats.offlineRevenueData &&
      cardStats.offlineRevenueData.length
    ) {
      let pendingSum = 0;
      let paidSum = 0;
      cardStats.offlineRevenueData.forEach((parking) => {
        if (parking.price.paymentStatus == "Pending")
          pendingSum += parking.price.amount;
        else if (parking.price.paymentStatus == "Paid")
          paidSum += parking.price.amount;
      });
      setOfflinePendingRevenue(pendingSum);
      setOfflinePaidRevenue(paidSum);
      total = total + pendingSum + paidSum;
    }
    setTotalRevenue(total);
  }, [cardStats.offlineRevenueData, cardStats.onlineRevenueData]);


  // Revenue comparison chart

  const getRevenueComparison = async () => {
    const startDate1 = moment(revenueRange1[0]).format("YYYY-MM-DD");
    const endDate1 = moment(revenueRange1[1]).format("YYYY-MM-DD");
    const startDate2 = moment(revenueRange2[0]).format("YYYY-MM-DD");
    const endDate2 = moment(revenueRange2[1]).format("YYYY-MM-DD");
    const data = await getRevenueComparisonAnalytics({
      startDate1, endDate1, startDate2, endDate2
    });
    console.log(data);
    setRevenueComparisonData(data);
  }

  useEffect(() => {
    console.log(revenueRange1, revenueRange2);
    if (revenueRange1 && revenueRange2) {
       getRevenueComparison();
    }
  }, [revenueRange1, revenueRange2]);


  return (
    <>
      <Helmet>
        <script src="/assets/plugins/apexchart/apexcharts.min.js"></script>
        <script src="/assets/plugins/apexchart/chart-data.js"></script>
      </Helmet>
      <div className="row">
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="dash-widget-icon bg-1">
                  <i className="fas fa-dollar-sign" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Revenue this week</div>
                  <div className="dash-counts">
                    <p>{cardStats.revenue.currentWeek}</p>
                  </div>
                </div>
              </div>
              <div className="progress progress-sm mt-3">
                <div
                  className="progress-bar bg-5"
                  role="progressbar"
                  style={{
                    width:
                      cardStats.revenue.percentDifference >= 0
                        ? cardStats.revenue.percentDifference + "%"
                        : 100 -
                          Math.abs(cardStats.revenue.percentDifference) +
                          "%",
                  }}
                  aria-valuenow={
                    cardStats.revenue.percentDifference >= 0
                      ? cardStats.revenue.percentDifference
                      : 100 - Math.abs(cardStats.revenue.percentDifference)
                  }
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <p className="text-muted mt-3 mb-0">
                {cardStats.revenue.percentDifference >= 0 ? (
                  <>
                    <span className="text-success me-1">
                      <i className="fas fa-arrow-up me-1" />
                      {cardStats.revenue.percentDifference}%
                    </span>{" "}
                    since last week
                  </>
                ) : (
                  <>
                    <span className="text-danger me-1">
                      <i className="fas fa-arrow-down me-1" />
                      {cardStats.revenue.percentDifference}%
                    </span>{" "}
                    since last week
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="dash-widget-icon bg-2">
                  <i className="fas fa-users" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Parking's this week</div>
                  <div className="dash-counts">
                    <p>{cardStats.parking.currentWeek}</p>
                  </div>
                </div>
              </div>
              <div className="progress progress-sm mt-3">
                <div
                  className="progress-bar bg-6"
                  role="progressbar"
                  style={{
                    width:
                      cardStats.parking.percentDifference >= 0
                        ? cardStats.parking.percentDifference + "%"
                        : 100 -
                          Math.abs(cardStats.parking.percentDifference) +
                          "%",
                  }}
                  aria-valuenow={
                    cardStats.parking.percentDifference >= 0
                      ? cardStats.parking.percentDifference
                      : 100 - Math.abs(cardStats.parking.percentDifference)
                  }
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <p className="text-muted mt-3 mb-0">
                {cardStats.parking.percentDifference >= 0 ? (
                  <>
                    <span className="text-success me-1">
                      <i className="fas fa-arrow-up me-1" />
                      {cardStats.parking.percentDifference}%
                    </span>{" "}
                    since last week
                  </>
                ) : (
                  <>
                    <span className="text-danger me-1">
                      <i className="fas fa-arrow-down me-1" />
                      {cardStats.parking.percentDifference}%
                    </span>{" "}
                    since last week
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="dash-widget-icon bg-3">
                  <i className="fas fa-file-alt" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Total Valets</div>
                  <div className="dash-counts">
                    <p>{cardStats.valets.currentWeek}</p>
                  </div>
                </div>
              </div>
              <div className="progress progress-sm mt-3">
                <div
                  className="progress-bar bg-7"
                  role="progressbar"
                  style={{
                    width:
                      cardStats.valets.percentDifference >= 0
                        ? cardStats.valets.percentDifference + "%"
                        : 100 -
                          Math.abs(cardStats.valets.percentDifference) +
                          "%",
                  }}
                  aria-valuenow={
                    cardStats.valets.percentDifference >= 0
                      ? cardStats.valets.percentDifference
                      : 100 - Math.abs(cardStats.valets.percentDifference)
                  }
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <p className="text-muted mt-3 mb-0">
                {cardStats.valets.percentDifference >= 0 ? (
                  <>
                    <span className="text-success me-1">
                      <i className="fas fa-arrow-up me-1" />
                      {cardStats.valets.percentDifference}%
                    </span>{" "}
                    since last week
                  </>
                ) : (
                  <>
                    <span className="text-danger me-1">
                      <i className="fas fa-arrow-down me-1" />
                      {cardStats.valets.percentDifference}%
                    </span>{" "}
                    since last week
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="dash-widget-icon bg-4">
                  <i className="far fa-file" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Total Venues</div>
                  <div className="dash-counts">
                    <p>{cardStats.venues.currentWeek}</p>
                  </div>
                </div>
              </div>
              <div className="progress progress-sm mt-3">
                <div
                  className="progress-bar bg-8"
                  role="progressbar"
                  style={{
                    width:
                      cardStats.venues.percentDifference >= 0
                        ? cardStats.venues.percentDifference + "%"
                        : 100 -
                          Math.abs(cardStats.venues.percentDifference) +
                          "%",
                  }}
                  aria-valuenow={
                    cardStats.venues.percentDifference >= 0
                      ? cardStats.venues.percentDifference
                      : 100 - Math.abs(cardStats.venues.percentDifference)
                  }
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <p className="text-muted mt-3 mb-0">
                {cardStats.venues.percentDifference >= 0 ? (
                  <>
                    <span className="text-success me-1">
                      <i className="fas fa-arrow-up me-1" />
                      {cardStats.venues.percentDifference}%
                    </span>{" "}
                    since last week
                  </>
                ) : (
                  <>
                    <span className="text-danger me-1">
                      <i className="fas fa-arrow-down me-1" />
                      {cardStats.venues.percentDifference}%
                    </span>{" "}
                    since last week
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="dash-widget-icon bg-1">
                  <i className="fas fa-dollar-sign" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Vehicle Parked</div>
                  <div className="dash-counts">
                    <p>{cardStats.parked.currentWeek}</p>
                  </div>
                </div>
              </div>
              <div className="progress progress-sm mt-3">
                <div
                  className="progress-bar bg-5"
                  role="progressbar"
                  style={{
                    width:
                      cardStats.parked.percentDifference >= 0
                        ? cardStats.parked.percentDifference + "%"
                        : 100 -
                          Math.abs(cardStats.parked.percentDifference) +
                          "%",
                  }}
                  aria-valuenow={
                    cardStats.parked.percentDifference >= 0
                      ? cardStats.parked.percentDifference
                      : 100 - Math.abs(cardStats.parked.percentDifference)
                  }
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <p className="text-muted mt-3 mb-0">
                {cardStats.parked.percentDifference >= 0 ? (
                  <>
                    <span className="text-success me-1">
                      <i className="fas fa-arrow-up me-1" />
                      {cardStats.parked.percentDifference}%
                    </span>{" "}
                    since last week
                  </>
                ) : (
                  <>
                    <span className="text-danger me-1">
                      <i className="fas fa-arrow-down me-1" />
                      {cardStats.parked.percentDifference}%
                    </span>{" "}
                    since last week
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="dash-widget-icon bg-1">
                  <i className="fas fa-dollar-sign" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Vehicle Delivered</div>
                  <div className="dash-counts">
                    <p>{cardStats.delivered.currentWeek}</p>
                  </div>
                </div>
              </div>
              <div className="progress progress-sm mt-3">
                <div
                  className="progress-bar bg-5"
                  role="progressbar"
                  style={{
                    width:
                      cardStats.delivered.percentDifference >= 0
                        ? cardStats.delivered.percentDifference + "%"
                        : 100 -
                          Math.abs(cardStats.delivered.percentDifference) +
                          "%",
                  }}
                  aria-valuenow={
                    cardStats.delivered.percentDifference >= 0
                      ? cardStats.delivered.percentDifference
                      : 100 - Math.abs(cardStats.delivered.percentDifference)
                  }
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <p className="text-muted mt-3 mb-0">
                {cardStats.delivered.percentDifference >= 0 ? (
                  <>
                    <span className="text-success me-1">
                      <i className="fas fa-arrow-up me-1" />
                      {cardStats.delivered.percentDifference}%
                    </span>{" "}
                    since last week
                  </>
                ) : (
                  <>
                    <span className="text-danger me-1">
                      <i className="fas fa-arrow-down me-1" />
                      {cardStats.delivered.percentDifference}%
                    </span>{" "}
                    since last week
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="dash-widget-icon bg-1">
                  <i className="fas fa-dollar-sign" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Total Revenue</div>
                  <div className="dash-counts">
                    <p>{totalRevenue}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="dash-widget-icon bg-1">
                  <i className="fas fa-dollar-sign" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Paid Online</div>
                  <div className="dash-counts">
                    <p>{onlineRevenue}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="dash-widget-icon bg-1">
                  <i className="fas fa-dollar-sign" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Paid By Cash</div>
                  <div className="dash-counts">
                    <p>{offlinePaidRevenue}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="dash-widget-icon bg-1">
                  <i className="fas fa-dollar-sign" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Remaining Payment</div>
                  <div className="dash-counts">
                    <p>{offlinePendingRevenue}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 d-flex">
          <div className="card flex-fill">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="card-title">Revenue Comparison By Date</h5>
                <div className="dropdown d-flex ">
                  <div className="m-3">
                    <label htmlFor="type" className="form-label">
                      First Day <span className="text-danger">(required)</span>
                    </label>
                    <div
                        style={{ position: 'relative' }}
                        className="row form-group mb-3"
                    >
                      <div className="col-sm-12">
                        <input type='datetime-local' onChange={async (e) =>  {
                          const data = await getCustomDailyRevenue(e.target.value);
                          setCustomDailyRevenueComparison({
                            ...customDailyRevenueComparison,
                            day1: data
                          })
                        }}/>
                      </div>
                    </div>
                  </div>
                  <div className="m-3">
                    <label htmlFor="type" className="form-label">
                      Second Day <span className="text-danger">(required)</span>
                    </label>
                    <div
                        style={{ position: 'relative' }}
                        className="row form-group mb-3"
                    >
                      <div className="col-sm-12">
                        <input type='datetime-local' onChange={async (e) =>  {
                          const data = await getCustomDailyRevenue(e.target.value);
                          setCustomDailyRevenueComparison({
                            ...customDailyRevenueComparison,
                            day2: data
                          })
                        }}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <Chart
                  options={{
                    chart: {
                      id: "revenue-comparison",
                    },
                    stroke: {
                      curve: 'smooth',
                    },
                    xaxis: {
                      categories: customDailyRevenueComparison.day1.xAxis,
                    },
                    plotOptions: {
                      bar: {
                        horizontal: false,
                        columnWidth: "40%",
                      },
                    },
                  }}
                  series={[
                    {
                      name: "Day 1",
                      data: customDailyRevenueComparison.day1.yAxis,
                      color: "#539165",
                    },
                    {
                      name: "Day 2",
                      data: customDailyRevenueComparison.day2.yAxis,
                      color: "#EA5455",
                    },
                  ]}
                  type="line"
                  height={600}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 d-flex">
          <div className="card flex-fill">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="card-title">Revenue Comparison By Month</h5>
                <div className="dropdown d-flex ">
                  <div className="m-3">
                    <label htmlFor="type" className="form-label">
                      First Month <span className="text-danger">(required)</span>
                    </label>
                    <div
                        style={{ position: 'relative' }}
                        className="row form-group mb-3"
                    >
                      <div className="col-sm-12">
                        <input type='datetime-local' onChange={async (e) =>  {
                          const date = moment(e.target.value);
                          const data = await getCustomMonthlyRevenue(date.month(), date.year());
                          setCustomMonthlyRevenueComparison({
                            ...customMonthlyRevenueComparison,
                            month1: data
                          })
                        }}/>
                      </div>
                    </div>
                  </div>
                  <div className="m-3">
                    <label htmlFor="type" className="form-label">
                      Second Month <span className="text-danger">(required)</span>
                    </label>
                    <div
                        style={{ position: 'relative' }}
                        className="row form-group mb-3"
                    >
                      <div className="col-sm-12">
                        <input type='datetime-local' onChange={async (e) =>  {
                          const date = moment(e.target.value);
                          const data = await getCustomMonthlyRevenue(date.month(), date.year());
                          setCustomMonthlyRevenueComparison({
                            ...customMonthlyRevenueComparison,
                            month2: data
                          })
                        }}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <Chart
                  options={{
                    chart: {
                      id: "revenue-comparison",
                    },
                    stroke: {
                      curve: 'smooth',
                    },
                    xaxis: {
                      categories: customMonthlyRevenueComparison.month1.xAxis,
                    },
                    plotOptions: {
                      bar: {
                        horizontal: false,
                        columnWidth: "40%",
                      },
                    },
                  }}
                  series={[
                    {
                      name: "Month 1",
                      data: customMonthlyRevenueComparison.month1.yAxis,
                      color: "#539165",
                    },
                    {
                      name: "Month 2",
                      data: customMonthlyRevenueComparison.month2.yAxis,
                      color: "#EA5455",
                    },
                  ]}
                  type="line"
                  height={600}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 d-flex">
          <div className="card flex-fill">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="card-title">Revenue Comparison By Year</h5>
                <div className="dropdown d-flex ">
                  <div className="m-3">
                    <label htmlFor="type" className="form-label">
                      First Year <span className="text-danger">(required)</span>
                    </label>
                    <div
                        style={{ position: 'relative' }}
                        className="row form-group mb-3"
                    >
                      <div className="col-sm-12">
                        <input type='number' className={'form-control w-50 m-0 p-0 text-center'} onChange={async (e) =>  {
                          setCustomYearlyRevenue({
                            ...customYearlyRevenue,
                            year1: e.target.value
                          })
                          const data = await getCustomYearlyRevenue(e.target.value);
                          setCustomYearlyRevenueComparison({
                            ...customYearlyRevenueComparison,
                            year1: data
                          })
                        }} value={customYearlyRevenue.year1}/>
                      </div>
                    </div>
                  </div>
                  <div className="m-3">
                    <label htmlFor="type" className="form-label">
                      Second Year <span className="text-danger">(required)</span>
                    </label>
                    <div
                        style={{ position: 'relative' }}
                        className="row form-group mb-3"
                    >
                      <div className="col-sm-12">
                        <input type='number' className={'form-control w-50 m-0 p-0 text-center'} onChange={async (e) =>  {
                          setCustomYearlyRevenue({
                            ...customYearlyRevenue,
                            year2: e.target.value
                          })
                          const data = await getCustomYearlyRevenue(e.target.value);
                          setCustomYearlyRevenueComparison({
                            ...customYearlyRevenueComparison,
                            year2: data
                          })
                        }} value={customYearlyRevenue.year2}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <Chart
                  options={{
                    chart: {
                      id: "revenue-comparison",
                    },
                    stroke: {
                      curve: 'smooth',
                    },
                    xaxis: {
                      categories: customYearlyRevenueComparison.year1.xAxis,
                    },
                    plotOptions: {
                      bar: {
                        horizontal: false,
                        columnWidth: "40%",
                      },
                    },
                  }}
                  series={[
                    {
                      name: "Year 1",
                      data: customYearlyRevenueComparison.year1.yAxis,
                      color: "#539165",
                    },
                    {
                      name: "Year 2",
                      data: customYearlyRevenueComparison.year2.yAxis,
                      color: "#EA5455",
                    },
                  ]}
                  type="line"
                  height={600}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 d-flex">
          <div className="card flex-fill">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="card-title">Revenue Analytics</h5>
                <div className="dropdown">
                  <button
                    className="btn btn-white btn-sm dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {revenueAnalyticsType}
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="dropdown-item"
                        onClick={(e) => setRevenueAnalyticsType("weekly")}
                      >
                        Weekly
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="dropdown-item"
                        onClick={(e) => setRevenueAnalyticsType("monthly")}
                      >
                        Monthly
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="dropdown-item"
                        onClick={(e) => setRevenueAnalyticsType("yearly")}
                      >
                        Yearly
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body">
              <Chart
                options={{
                  chart: {
                    id: "revenue-analytics",
                  },
                  xaxis: {
                    categories: revenueAnalytics.paid.xAxis,
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: "40%",
                    },
                  },
                }}
                series={[
                  {
                    name: "revenue-paid",
                    data: revenueAnalytics.paid.yAxis,
                    color: "#539165",
                  },
                  {
                    name: "revenue-unpaid",
                    data: revenueAnalytics.unpaid.yAxis,
                    color: "#EA5455",
                  },
                ]}
                type="bar"
                height={600}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 col-md-12 d-flex">
          <div className="card flex-fill">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="card-title">Venues Registered</h5>
              </div>
            </div>
            <div className="card-body">
              <Chart
                options={{
                  chart: {
                    id: "venues-registered",
                  },
                  xaxis: {
                    categories: venueCount.xAxis,
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: "40%",
                    },
                  },
                }}
                series={[
                  {
                    name: "Venue Registered",
                    data: venueCount.yAxis,
                    color: "#539165",
                  },
                ]}
                type="line"
                height={600}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-md-12 d-flex">
          <div className="card flex-fill">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="card-title">Parking Type Distribution</h5>
              </div>
            </div>
            <div className="card-body">
              <Chart
                options={{
                  chart: {
                    id: "parking-slot-distribution",
                  },
                  labels: parkingSlotDistribution.xAxis,
                }}
                series={parkingSlotDistribution.yAxis}
                type="donut"
                height={400}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-md-12 d-flex">
          <div className="card flex-fill">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="card-title">Venue Type Distribution</h5>
              </div>
            </div>
            <div className="card-body">
              <Chart
                options={{
                  chart: {
                    id: "venue-type-distribution",
                  },
                  labels: venueTypeCount.xAxis,
                }}
                series={venueTypeCount.yAxis}
                type="donut"
                height={400}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-md-12 d-flex">
          <div className="card flex-fill">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="card-title">Parkings Analytics</h5>
                <div className="dropdown">
                  <button
                    className="btn btn-white btn-sm dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {parkingAnalyticsType}
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="dropdown-item"
                        onClick={(e) => setParkingAnalyticsType("weekly")}
                      >
                        Weekly
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="dropdown-item"
                        onClick={(e) => setParkingAnalyticsType("monthly")}
                      >
                        Monthly
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="dropdown-item"
                        onClick={(e) => setParkingAnalyticsType("daily")}
                      >
                        Daily
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body">
              <Chart
                options={{
                  chart: {
                    id: "parking-analytics",
                  },
                  xaxis: {
                    categories: parkingAnalytics.parking.xAxis,
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: "40%",
                    },
                  },
                }}
                series={[
                  {
                    name: "Parking",
                    data: parkingAnalytics.parking.yAxis,
                    color: "#539165",
                  },
                  {
                    name: "Requests",
                    data: parkingAnalytics.requests.yAxis,
                    color: "#EA5455",
                  },
                ]}
                type="area"
                height={600}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row"></div>
    </>
  );
};

export default Dashboard;
