import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import store from '../../store';
import { updateProfile } from '../../store/auth/action';
import {faUser, faEnvelope, faKey} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from "react-bootstrap-button-loader";



const EditProfile = () => {
    let auth = useSelector((state) => state.auth);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let data = { name, username, password };
        store.dispatch(updateProfile(data));
    }

    useEffect(() => {
        setName(auth.name);
        setEmail(auth.email);
        setUsername(auth.username);
    }, [auth]);

    return (
        <div className="d-flex justify-content-center">
            <div className="card edit-profile-card">
                <div style={{border: "0"}} className="card-header">
                    <h5 className="card-title text-center">Edit profile</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Name <span
                                className='text-danger'>(required)</span></label>
                            <div style={{ position: "relative" }} className="row form-group mb-3">
                                <div className="col-sm-12">
                                    <input type="text" className="form-control w-100" id="name" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required
                                    />
                                    <div className="prefix-icon">
                                        <FontAwesomeIcon icon={faUser} color="#9079E9" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="username" className="form-label">Username <span
                                className='text-danger'>(required)</span></label>
                            <div style={{ position: "relative" }} className="row form-group">
                                <div className="col-sm-12">
                                    <input type="text" className="form-control w-100" id="username" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required
                                    />
                                    <div className="prefix-icon">
                                        <FontAwesomeIcon icon={faUser} color="#9079E9" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email <span
                                className='text-danger'>(required)</span></label>
                            <div style={{ position: "relative" }} className="row form-group">
                                <div className="col-sm-12">
                                    <input type="text" className="form-control w-100" id="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required
                                    />
                                    <div className="prefix-icon">
                                        <FontAwesomeIcon icon={faEnvelope} color="#9079E9" />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">New Password</label>
                            <div style={{ position: "relative" }} className="row form-group">
                                <div className="col-sm-12">
                                    <input type="text" className="form-control w-100" id="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <div className="prefix-icon">
                                        <FontAwesomeIcon icon={faKey} color="#9079E9" />
                                    </div>
                                </div>
                            </div>
                        </div>
                       <div className='d-flex justify-content-center'>
                           <Button loading={loading} type="submit" className="btn btn-primary me-2 w-75" disabled={loading}><i
                               className="fa fa-check-circle me-2" />Submit</Button>
                       </div>
                    </form>

                </div>
            </div>
        </div>
    );
}

export default EditProfile;
