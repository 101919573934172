import axios from "axios";
import baseConfig from "../../Config";

export const getDashboardCardStats = async () => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/dashboard/card-stats`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
               console.log(err);
            }
            return null;
        }

    }
}

export const getRevenueComparisonAnalytics = async (data) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.post(
                `${baseConfig.apiBaseUrl}/admin/dashboard/revenue/compare`,
                data,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}

export const getMonthlyRevenueAnalytics = async () => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/dashboard/revenue/monthly`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}

export const getWeeklyRevenueAnalytics = async () => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/dashboard/revenue/weekly`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}

export const getYearlyRevenueAnalytics = async () => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/dashboard/revenue/yearly`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}

export const getParkingSlotDistribution = async () => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/dashboard/parking-slot-distribution`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}


export const getRegisteredVenue = async () => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/dashboard/venues/monthly`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}

export const getMonthlyParkingsAndRequests = async () => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/dashboard/parkings/monthly`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}

export const getWeeklyParkingsAndRequests = async () => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/dashboard/parkings/weekly`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}

export const getDailyParkingsAndRequests = async () => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/dashboard/parkings/daily`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}

export const getVenueTypeDistribution = async () => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/dashboard/venue-type-distribution`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}

export const getCustomDailyRevenue = async (date) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/dashboard/custom/revenue/daily?date=${date}`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}

export const getCustomMonthlyRevenue = async (month, year) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/dashboard/custom/revenue/monthly?month=${month}&year=${year}`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}

export const getCustomYearlyRevenue = async (year) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/admin/dashboard/custom/revenue/yearly?year=${year}`,
                config
            );
            if (res.data['status']) {
                return res.data.data;
            } else {
                return null;
            }
        } catch (err) {
            if (err.response) {
                console.log(err);
            }
            return null;
        }

    }
}
