import { useNavigate, useParams } from "react-router";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  assignStaffByVenue,
  getMonthlyRevenueAnalytics,
  getParkingSlotDistribution,
  getVenueCardStats, getVenueRevenueComparisonAnalytics,
  getWeeklyRevenueAnalytics,
  getYearlyRevenueAnalytics,
} from "../../store/venue/action";
import { Helmet } from "react-helmet";
import Chart from "react-apexcharts";
import io from "socket.io-client";
import config from "../../Config";
import { Link } from "react-router-dom";
import Select from "react-select";
import store from "../../store";
import { assignValetToRequest } from "../../store/parking/action";
import {
  getDailyParkingsAndRequests,
  getMonthlyParkingsAndRequests,
  getWeeklyParkingsAndRequests,
} from "../../store/venue/action";
import moment from "moment";
import $ from "jquery";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

const VenueDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const allVenues = useSelector((state) => state.venue.venues);
  const allStaff = useSelector((state) => state.staff.admins);
  const [venue, setVenue] = useState(null);
  const [valets, setValets] = useState([]);
  const [assignedValets, setAssignedValets] = useState([]);
  const [allValets, setAllValets] = useState([]);
  const [filteredNotofications, setFilteredNotofications] = useState([]);
  const [onlineRevenue, setOnlineRevenue] = useState(0);
  const [offlinePaidRevenue, setOfflinePaidRevenue] = useState(0);
  const [offlinePendingRevenue, setOfflinePendingRevenue] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [revenueRange1, setRevenueRange1] = useState([new Date(), new Date()]);
  const [revenueRange2, setRevenueRange2] = useState([new Date(), new Date()]);
  const [revenueComparisonData, setRevenueComparisonData] = useState({
    range1: [],
    range2: [],
  });

  const allNotifications = useSelector(
    (state) => state.notification.notifications
  );
  const allParking = useSelector((state) => state.parking.parking);

  useEffect(() => {
    let temp = allParking.filter((parking) => parking.venue.id == params.venue);
    let temp2 = allNotifications
      .filter((notification) => {
        const index = temp.findIndex(
          (x) => x.ticket == notification.payload.ticket
        );
        if (index !== -1) return true;
        else return false;
      })
      .map((notification, index) => {
        const duration = moment.duration(
          moment().diff(moment(notification.createdAt))
        );
        const hours = parseInt(duration.asHours().toString());
        const minutes = parseInt(duration.asMinutes().toString());
        return [
          index + 1,
          notification.title,
          notification.message,
          notification.payload.ticket,
          hours > 24
            ? moment(notification.createdAt || "").format(
                "MMMM Do YYYY, h:mm:ss a"
              )
            : minutes > 60
            ? `${hours} hours ago`
            : `${minutes} min ago`,
        ];
      });
    setFilteredNotofications(temp2.reverse());
  }, [allNotifications, allParking]);

  useEffect(() => {
    try {
      $("#table").DataTable().destroy();
    } catch (e) {
      console.log(e);
    }
    $("#table").DataTable({
      data: filteredNotofications,
      columnDefs: [
        {
          render: function (data, type, row) {
            return `<p class="badge bg-primary" style="cursor: pointer">#${data}</p>`;
          },
          createdCell: (td, cellData, rowData, row, col) => {
            $(td).click((e) => {
              console.log("CLICKED");
              navigate(`/parking/${cellData}`);
            });
          },
          targets: 3,
        },
      ],
      order: [],
    });
  }, [filteredNotofications]);

  const [cardStats, setCardStats] = useState({
    revenue: {
      currentWeek: 0,
      previousWeek: 0,
      percentDifference: 0,
    },
    parking: {
      currentWeek: 0,
      previousWeek: 0,
      percentDifference: 0,
    },
    valets: {
      currentWeek: 0,
      previousWeek: 0,
      percentDifference: 0,
    },
    parked: {
      currentWeek: 0,
      previousWeek: 0,
      percentDifference: 0,
    },
    onlineRevenueData: [],
    offlineRevenueData: [],
    chartRevenue: { today: [], lastWeek: [], lastMonth: [] },
  });
  const [parkingSlotDistribution, setParkingSlotDistribution] = useState({
    xAxis: [],
    yAxis: [],
  });

  const socket = io(config.socketBaseUrl);
  socket.on("parking", (arg) => {
    if (venue != null) {
      getCardStats(venue._id);
      getSlotDistribution(venue._id);
      switch (revenueAnalyticsType) {
        case "monthly":
          getMonthlyRevenue(venue._id);
          break;
        case "weekly":
          getWeeklyRevenue(venue._id);
          break;
        case "yearly":
          getYearlyRevenue(venue._id);
          break;
        default:
          getMonthlyRevenue(venue._id);
      }
    }
  });

  const [revenueAnalyticsType, setRevenueAnalyticsType] = useState("monthly");
  const [revenueAnalytics, setRevenueAnalytics] = useState({
    paid: {
      xAxis: [],
      yAxis: [],
    },
    unpaid: {
      xAxis: [],
      yAxis: [],
    },
  });

  const [parkingAnalyticsType, setParkingAnalyticsType] = useState("monthly");
  const [parkingAnalytics, setParkingAnalytics] = useState({
    parking: {
      xAxis: [],
      yAxis: [],
    },
    requests: {
      xAxis: [],
      yAxis: [],
    },
  });

  const getCardStats = async (id) => {
    const stats = await getVenueCardStats(id);
    setCardStats(stats);
  };

  const getSlotDistribution = async (id) => {
    const distribution = await getParkingSlotDistribution(id);
    setParkingSlotDistribution({
      ...distribution,
    });
  };

  const getMonthlyRevenue = async (id) => {
    const stats = await getMonthlyRevenueAnalytics(id);
    setRevenueAnalytics({
      ...stats,
    });
  };

  const getWeeklyRevenue = async (id) => {
    const stats = await getWeeklyRevenueAnalytics(id);
    setRevenueAnalytics({
      ...stats,
    });
  };

  const getYearlyRevenue = async (id) => {
    const stats = await getYearlyRevenueAnalytics(id);
    setRevenueAnalytics({
      ...stats,
    });
  };

  const getMonthlyParkingAnalytics = async (id) => {
    const stats = await getMonthlyParkingsAndRequests(id);
    setParkingAnalytics({
      ...stats,
    });
  };

  const getWeeklyParkingAnalytics = async (id) => {
    const stats = await getWeeklyParkingsAndRequests(id);
    setParkingAnalytics({
      ...stats,
    });
  };

  const getDailyParkingAnalytics = async (id) => {
    const stats = await getDailyParkingsAndRequests(id);
    setParkingAnalytics({
      ...stats,
    });
  };

  useEffect(() => {
    let _venue = allVenues.find((venue) => venue._id === params.venue);
    if (_venue) {
      setVenue(_venue);
      getCardStats(_venue._id);
      getSlotDistribution(_venue._id);
    }
  }, [allVenues]);

  useEffect(() => {
    if (venue != null) {
      switch (revenueAnalyticsType) {
        case "monthly":
          getMonthlyRevenue(venue._id);
          break;
        case "weekly":
          getWeeklyRevenue(venue._id);
          break;
        case "yearly":
          getYearlyRevenue(venue._id);
          break;
        default:
          getMonthlyRevenue(venue._id);
      }
    }
  }, [venue, revenueAnalyticsType]);

  useEffect(() => {
    if (venue != null) {
      switch (parkingAnalyticsType) {
        case "monthly":
          getMonthlyParkingAnalytics(venue._id);
          break;
        case "weekly":
          getWeeklyParkingAnalytics(venue._id);
          break;
        case "daily":
          getDailyParkingAnalytics(venue._id);
          break;
        default:
          getMonthlyParkingAnalytics(venue._id);
      }
    }
  }, [venue, parkingAnalyticsType]);

  useEffect(() => {
    if (venue != null) {
      let _valets = allStaff.filter(
        (staff) =>
          staff.venues.includes(venue._id) &&
          staff.type === "Valet" &&
          staff.status === "Active"
      );
      setValets(_valets);
      let assigned = [];
      for (const v of _valets) {
        console.log(v);
        assigned.push({
          value: v._id,
          label: `${v.name} (${v.available ? "AVAILABLE" : "NOT AVAILABLE"})`,
        });
      }
      setAssignedValets(assigned);
    }
  }, [allStaff, venue]);

  useEffect(() => {
    if (venue != null) {
      let _staff = [];
      for (let s of allStaff) {
        if (s.type === "Valet" && s.status === "Active") {
          _staff.push({
            value: s._id,
            label: `${s.name} (${s.available ? "AVAILABLE" : "NOT AVAILABLE"})`,
          });
        }
      }
      setAllValets(_staff);
    }
  }, [allStaff, venue]);

  useEffect(() => {
    let total = 0;
    if (
      cardStats &&
      cardStats.onlineRevenueData &&
      cardStats.onlineRevenueData.length
    ) {
      let sum = 0;
      cardStats.onlineRevenueData.forEach((parking) => {
        sum += parking.price.amount;
      });
      setOnlineRevenue(sum);
      total += sum;
    }
    if (
      cardStats &&
      cardStats.offlineRevenueData &&
      cardStats.offlineRevenueData.length
    ) {
      let pendingSum = 0;
      let paidSum = 0;
      cardStats.offlineRevenueData.forEach((parking) => {
        if (parking.price.paymentStatus == "Pending")
          pendingSum += parking.price.amount;
        else if (parking.price.paymentStatus == "Paid")
          paidSum += parking.price.amount;
      });
      setOfflinePendingRevenue(pendingSum);
      setOfflinePaidRevenue(paidSum);
      total = total + pendingSum + paidSum;
    }
    setTotalRevenue(total);
  }, [cardStats.offlineRevenueData, cardStats.onlineRevenueData]);

  const [revenueTimeType, setRevenueTimeType] = useState("Last Month");
  const [revenueDistibution, setRevenueDistibution] = useState({
    xAxis: [],
    yAxis: [],
  });

  useEffect(() => {
    const getAxis = (data = []) => {
      let paidOnline = 0;
      let paidByCash = 0;
      let remainPayment = 0;
      if (data && data.length)
        data.forEach((item, i) => {
          console.log({ item });
          if (
            item.price.paymentMethod === "Offline" &&
            item.price.paymentStatus === "Paid"
          ) {
            paidByCash += item.price.amount;
          } else if (
            item.price.paymentMethod === "Online" &&
            item.price.paymentStatus === "Paid"
          ) {
            paidOnline += item.price.amount;
          } else if (item.price.paymentStatus === "Pending") {
            remainPayment += item.price.amount;
          }
        });
      return [paidOnline, paidByCash, remainPayment];
    };
    console.log({ revenueTimeType });
    const xAxis = ["Paid Online", "Paid By Cash", "Remain Payment"];
    if (revenueTimeType === "Last Month") {
      const yAxis = getAxis(cardStats.chartRevenue.lastMonth);
      setRevenueDistibution({ xAxis, yAxis });
    } else if (revenueTimeType === "Last Week") {
      const yAxis = getAxis(cardStats.chartRevenue.lastWeek);
      setRevenueDistibution({ xAxis, yAxis });
    } else {
      const yAxis = getAxis(cardStats.chartRevenue.today);
      setRevenueDistibution({ xAxis, yAxis });
    }
  }, [revenueTimeType, cardStats.chartRevenue]);


  // Revenue comparison chart

  const getRevenueComparison = async () => {
    const startDate1 = moment(revenueRange1[0]).format("YYYY-MM-DD");
    const endDate1 = moment(revenueRange1[1]).format("YYYY-MM-DD");
    const startDate2 = moment(revenueRange2[0]).format("YYYY-MM-DD");
    const endDate2 = moment(revenueRange2[1]).format("YYYY-MM-DD");
    const data = await getVenueRevenueComparisonAnalytics(params.venue,{
      startDate1, endDate1, startDate2, endDate2
    });
    console.log(data);
    setRevenueComparisonData(data);
  }

  useEffect(() => {
    if (revenueRange1 && revenueRange2) {
      getRevenueComparison();
    }
  }, [revenueRange1, revenueRange2]);

  return (
    <>
      <Helmet>
        <script src="/assets/plugins/apexchart/apexcharts.min.js"></script>
        <script src="/assets/plugins/apexchart/chart-data.js"></script>
      </Helmet>
      <div className="row">
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="dash-widget-icon bg-1">
                  <i className="fas fa-dollar-sign" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Revenue this week</div>
                  <div className="dash-counts">
                    <p>{cardStats.revenue.currentWeek}</p>
                  </div>
                </div>
              </div>
              <div className="progress progress-sm mt-3">
                <div
                  className="progress-bar bg-5"
                  role="progressbar"
                  style={{
                    width:
                      cardStats.revenue.percentDifference >= 0
                        ? cardStats.revenue.percentDifference + "%"
                        : 100 -
                          Math.abs(cardStats.revenue.percentDifference) +
                          "%",
                  }}
                  aria-valuenow={
                    cardStats.revenue.percentDifference >= 0
                      ? cardStats.revenue.percentDifference
                      : 100 - Math.abs(cardStats.revenue.percentDifference)
                  }
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <p className="text-muted mt-3 mb-0">
                {cardStats.revenue.percentDifference >= 0 ? (
                  <>
                    <span className="text-success me-1">
                      <i className="fas fa-arrow-up me-1" />
                      {cardStats.revenue.percentDifference}%
                    </span>{" "}
                    since last week
                  </>
                ) : (
                  <>
                    <span className="text-danger me-1">
                      <i className="fas fa-arrow-down me-1" />
                      {cardStats.revenue.percentDifference}%
                    </span>{" "}
                    since last week
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="dash-widget-icon bg-2">
                  <i className="fas fa-users" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Parking's this week</div>
                  <div className="dash-counts">
                    <p>{cardStats.parking.currentWeek}</p>
                  </div>
                </div>
              </div>
              <div className="progress progress-sm mt-3">
                <div
                  className="progress-bar bg-6"
                  role="progressbar"
                  style={{
                    width:
                      cardStats.parking.percentDifference >= 0
                        ? cardStats.parking.percentDifference + "%"
                        : 100 -
                          Math.abs(cardStats.parking.percentDifference) +
                          "%",
                  }}
                  aria-valuenow={
                    cardStats.parking.percentDifference >= 0
                      ? cardStats.parking.percentDifference
                      : 100 - Math.abs(cardStats.parking.percentDifference)
                  }
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <p className="text-muted mt-3 mb-0">
                {cardStats.parking.percentDifference >= 0 ? (
                  <>
                    <span className="text-success me-1">
                      <i className="fas fa-arrow-up me-1" />
                      {cardStats.parking.percentDifference}%
                    </span>{" "}
                    since last week
                  </>
                ) : (
                  <>
                    <span className="text-danger me-1">
                      <i className="fas fa-arrow-down me-1" />
                      {cardStats.parking.percentDifference}%
                    </span>{" "}
                    since last week
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="dash-widget-icon bg-3">
                  <i className="fas fa-file-alt" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Total Valets</div>
                  <div className="dash-counts">
                    <p>{cardStats.valets.currentWeek}</p>
                  </div>
                </div>
              </div>
              <div className="progress progress-sm mt-3">
                <div
                  className="progress-bar bg-7"
                  role="progressbar"
                  style={{
                    width:
                      cardStats.valets.percentDifference >= 0
                        ? cardStats.valets.percentDifference + "%"
                        : 100 -
                          Math.abs(cardStats.valets.percentDifference) +
                          "%",
                  }}
                  aria-valuenow={
                    cardStats.valets.percentDifference >= 0
                      ? cardStats.valets.percentDifference
                      : 100 - Math.abs(cardStats.valets.percentDifference)
                  }
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <p className="text-muted mt-3 mb-0">
                {cardStats.valets.percentDifference >= 0 ? (
                  <>
                    <span className="text-success me-1">
                      <i className="fas fa-arrow-up me-1" />
                      {cardStats.valets.percentDifference}%
                    </span>{" "}
                    since last week
                  </>
                ) : (
                  <>
                    <span className="text-danger me-1">
                      <i className="fas fa-arrow-down me-1" />
                      {cardStats.valets.percentDifference}%
                    </span>{" "}
                    since last week
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="dash-widget-icon bg-1">
                  <i className="fas fa-dollar-sign" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Vehicle Parked</div>
                  <div className="dash-counts">
                    <p>{cardStats.parked.currentWeek}</p>
                  </div>
                </div>
              </div>
              <div className="progress progress-sm mt-3">
                <div
                  className="progress-bar bg-5"
                  role="progressbar"
                  style={{
                    width:
                      cardStats.parked.percentDifference >= 0
                        ? cardStats.parked.percentDifference + "%"
                        : 100 -
                          Math.abs(cardStats.parked.percentDifference) +
                          "%",
                  }}
                  aria-valuenow={
                    cardStats.parked.percentDifference >= 0
                      ? cardStats.parked.percentDifference
                      : 100 - Math.abs(cardStats.parked.percentDifference)
                  }
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <p className="text-muted mt-3 mb-0">
                {cardStats.parked.percentDifference >= 0 ? (
                  <>
                    <span className="text-success me-1">
                      <i className="fas fa-arrow-up me-1" />
                      {cardStats.parked.percentDifference}%
                    </span>{" "}
                    since last week
                  </>
                ) : (
                  <>
                    <span className="text-danger me-1">
                      <i className="fas fa-arrow-down me-1" />
                      {cardStats.parked.percentDifference}%
                    </span>{" "}
                    since last week
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="dash-widget-icon bg-1">
                  <i className="fas fa-dollar-sign" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Total Revenue</div>
                  <div className="dash-counts">
                    <p>{totalRevenue}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="dash-widget-icon bg-1">
                  <i className="fas fa-dollar-sign" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Paid Online</div>
                  <div className="dash-counts">
                    <p>{onlineRevenue}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="dash-widget-icon bg-1">
                  <i className="fas fa-dollar-sign" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Paid By Cash</div>
                  <div className="dash-counts">
                    <p>{offlinePaidRevenue}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="dash-widget-icon bg-1">
                  <i className="fas fa-dollar-sign" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Remaining Payment</div>
                  <div className="dash-counts">
                    <p>{offlinePendingRevenue}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-3">
        <div className="card-header">
          <h5 className="card-title">Recent Tickets</h5>
        </div>
        <div class="card-body">
          <div className="table-responsive">
            <table id="table" className="table datatable">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Title</th>
                  <th>Message</th>
                  <th>Ticket</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 col-md-12 d-flex">
          <div className="card flex-fill">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="card-title">Parkings Analytics</h5>
                <div className="dropdown">
                  <button
                    className="btn btn-white btn-sm dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {parkingAnalyticsType}
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="dropdown-item"
                        onClick={(e) => setParkingAnalyticsType("weekly")}
                      >
                        Weekly
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="dropdown-item"
                        onClick={(e) => setParkingAnalyticsType("monthly")}
                      >
                        Monthly
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="dropdown-item"
                        onClick={(e) => setParkingAnalyticsType("daily")}
                      >
                        Daily
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body">
              <Chart
                options={{
                  chart: {
                    id: "parking-analytics",
                  },
                  xaxis: {
                    categories: parkingAnalytics.parking.xAxis,
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: "40%",
                    },
                  },
                }}
                series={[
                  {
                    name: "Parking",
                    data: parkingAnalytics.parking.yAxis,
                    color: "#539165",
                  },
                  {
                    name: "Requests",
                    data: parkingAnalytics.requests.yAxis,
                    color: "#EA5455",
                  },
                ]}
                type="area"
                height={600}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-md-12 d-flex">
          <div className="row">
            <div className="col-xl-12 col-md-12 d-flex">
              <div className="card flex-fill">
                <div className="card-header">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="card-title">Revanue Distribution</h5>
                    <div className="dropdown">
                      <button
                        className="btn btn-white btn-sm dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {revenueTimeType}
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <li>
                          <a
                            href="javascript:void(0);"
                            className="dropdown-item"
                            onClick={(e) => setRevenueTimeType("Last Month")}
                          >
                            Last Month
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            className="dropdown-item"
                            onClick={(e) => setRevenueTimeType("Last Week")}
                          >
                            Last Week
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            className="dropdown-item"
                            onClick={(e) => setRevenueTimeType("Today")}
                          >
                            Today
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {console.log({ revenueDistibution })}
                <div className="card-body">
                  <Chart
                    options={{
                      chart: {
                        id: "revenue-distribution",
                      },
                      labels: revenueDistibution.xAxis,
                      dataLabels: {
                        enabled: true,
                        formatter: function (_, opt) {
                          return `$ ${opt.w.config.series[opt.seriesIndex]}`;
                        },
                        background: {
                          // foreColor: "#000",
                          // dropShadow: { enabled: true, color: "#000" },
                          // borderColor: "#000",
                          // borderWidth: "2px",
                          // enabled: true,
                        },
                      },
                    }}
                    series={revenueDistibution.yAxis}
                    type="donut"
                    height={400}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-md-12 d-flex">
              <div className="card flex-fill">
                <div className="card-header">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="card-title">Parking Type Distribution</h5>
                  </div>
                </div>
                <div className="card-body">
                  <Chart
                    options={{
                      chart: {
                        id: "parking-slot-distribution",
                      },
                      labels: parkingSlotDistribution.xAxis,
                    }}
                    series={parkingSlotDistribution.yAxis}
                    type="donut"
                    height={400}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-9 col-md-12 d-flex">
          <div className="card flex-fill">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="card-title">Revenue Analytics</h5>
                <div className="dropdown">
                  <button
                    className="btn btn-white btn-sm dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {revenueAnalyticsType}
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="dropdown-item"
                        onClick={(e) => setRevenueAnalyticsType("weekly")}
                      >
                        Weekly
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="dropdown-item"
                        onClick={(e) => setRevenueAnalyticsType("monthly")}
                      >
                        Monthly
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="dropdown-item"
                        onClick={(e) => setRevenueAnalyticsType("yearly")}
                      >
                        Yearly
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body">
              <Chart
                options={{
                  chart: {
                    id: "revenue-analytics",
                  },
                  xaxis: {
                    categories: revenueAnalytics.paid.xAxis,
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: "40%",
                    },
                  },
                }}
                series={[
                  {
                    name: "revenue-paid",
                    data: revenueAnalytics.paid.yAxis,
                    color: "#539165",
                  },
                  {
                    name: "revenue-unpaid",
                    data: revenueAnalytics.unpaid.yAxis,
                    color: "#EA5455",
                  },
                ]}
                type="bar"
                height={600}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-12 d-flex">
          <div className="card flex-fill">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="card-title">Valet's</h5>
              </div>
            </div>
            <div className="card-body" style={{ height: "700px" }}>
              <div
                className="container"
                style={{
                  overflowY: "auto",
                  height: "100%",
                  maxHeight: "700px",
                }}
              >
                <div className="row text-center">
                  {valets.map((valet, index) => {
                    return (
                      <div className="col-12" key={index}>
                        <div className="card">
                          <div className="card-body">
                            <p
                              style={{
                                position: "relative",
                                top: 0,
                                right: 0,
                              }}
                            >
                              {valet.available ? (
                                <span className="badge bg-success float-end">
                                  Online
                                </span>
                              ) : (
                                <span className="badge bg-danger float-end">
                                  Offline
                                </span>
                              )}
                            </p>
                            <div className="avatar-sm mx-auto mb-4">
                              <span className="avatar-title rounded-circle bg-soft-primary text-white font-size-16">
                                {valet.name.charAt(0)}
                              </span>
                            </div>
                            <h5 className="font-size-15 text-truncate">
                              {valet.name}
                            </h5>
                            <p className="text-muted">{valet.email}</p>
                            <div className="mt-4">
                              <Link
                                to={`/staff/valet/edit/${valet._id}`}
                                className="btn btn-primary btn-sm"
                              >
                                View Profile{" "}
                                <i className="mdi mdi-arrow-right ms-1" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 d-flex">
          <div className="card flex-fill">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="card-title">Revenue Comparison</h5>
                <div className="dropdown d-flex ">
                  <div className="m-3">
                    <label htmlFor="type" className="form-label">
                      Date Range 1st <span className="text-danger">(required)</span>
                    </label>
                    <div
                        style={{ position: 'relative' }}
                        className="row form-group mb-3"
                    >
                      <div className="col-sm-12">
                        <DateRangePicker onChange={setRevenueRange1} value={revenueRange1} />
                      </div>
                    </div>
                  </div>
                  <div className="m-3">
                    <label htmlFor="type" className="form-label">
                      Date Range 2nd <span className="text-danger">(required)</span>
                    </label>
                    <div
                        style={{ position: 'relative' }}
                        className="row form-group mb-3"
                    >
                      <div className="col-sm-12">
                        <DateRangePicker onChange={setRevenueRange2} value={revenueRange2} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <Chart
                  options={{
                    chart: {
                      id: "revenue-comparison",
                    },
                    stroke: {
                      curve: 'smooth',
                    },
                    plotOptions: {
                      bar: {
                        horizontal: false,
                        columnWidth: "40%",
                      },
                    },
                  }}
                  series={[
                    {
                      name: "Range 1",
                      data: revenueComparisonData.range1,
                      color: "#539165",
                    },
                    {
                      name: "Range 2",
                      data: revenueComparisonData.range2,
                      color: "#EA5455",
                    },
                  ]}
                  type="line"
                  height={600}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 d-flex">
          <div className="card flex-fill">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="card-title">Assign/Free Valet</h5>
              </div>
            </div>
            <div className="card-body">
              <div className="mb-3 col-12">
                <Select
                  name="staff"
                  className="w-100"
                  options={allValets}
                  value={assignedValets}
                  isMulti={true}
                  onChange={(e) => {
                    console.log(
                      venue._id,
                      e.map((valet) => valet.value)
                    );
                    // store.dispatch(assignValetToRequest(request._id, e.value));
                    assignStaffByVenue(venue._id, {
                      staffs: e.map((valet) => valet.value),
                    });
                    setAssignedValets([...e]);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VenueDetails;
