import React, {useEffect, useState} from 'react';
import {upload} from "../../store/utility/action";
import Button from 'react-bootstrap-button-loader';
import store from "../../store";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import Select from 'react-select';
import {addStaff} from "../../store/staff/action";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";


const AddAdminStaff = () => {
    const params = useParams();
    const user = useSelector((state) => state.auth);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [cfPassword, setCfPassword] = useState("");
    const [venues, setVenues] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [profilePic, setProfilePic] = useState(null);
    const [profilePicUrl, setProfilePicUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [venueOptions, setVenueOptions] = useState([]);
    const [permissionOptions, setPermissionOptions] = useState([]);
    const allVenues = useSelector((state) => state.venue.venues);
    const allPermissions = useSelector((state) => state.staff.permissions);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let imageUrl = "";
        if (profilePic !== null) {
            imageUrl = await uploadImage();
        }
        if (password !== cfPassword) {
            toast.error("Passwords do not match", {});
            setLoading(false);
            return;
        }
        const data = {
            name,
            profilePic: imageUrl,
            email,
            password,
            type: params.type.charAt(0).toUpperCase() + params.type.slice(1),
            venues,
            permissions,
            phoneNumber
        };

        await store.dispatch(addStaff(data));
        e.target.reset();
        setLoading(false);
    };

    const uploadImage = async () => {
        let formData = new FormData();
        formData.append("image", profilePic);
        const urls = await upload(formData);
        return urls.length > 0 ? urls[0] : '';
    }



    useEffect(() => {
        let _venues = [];
        for (let venue of allVenues) {
            if (!venue.deleted) {
                if (user.type === 'Manager' && user.venues.includes(venue._id)) {
                    _venues.push({value: venue._id, label: venue.name},)
                } else if (user.type !== 'Manager') {
                    _venues.push({value: venue._id, label: venue.name},)
                }
            }

        }
        setVenueOptions(_venues);
    }, [allVenues]);

    useEffect(() => {
        let _permissions = [];
        for (let permission of allPermissions) {
            _permissions.push({value: permission._id, label: permission.title},)
        }
        setPermissionOptions(_permissions);
    }, [allPermissions]);



    return (<>
        <div className="d-flex justify-content-center">
            <div className="card add-staff-card mt-10">
                <div style={{border: "0"}} className="card-header">
                    <h5 className="card-title text-center">Add {params.type}</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row form-group">
                            <div className="col-12">
                                <div className="row">
                                    <label className="col-4 avatar avatar-xxl profile-cover-avatar m-0" htmlFor="edit_img" style={{
                                        backgroundImage: profilePicUrl !== null ? `url('${profilePicUrl}')` : "url('https://kanakku.dreamguystech.com/html/template/assets/img/profiles/avatar-02.jpg')",
                                        backgroundSize: "cover",
                                        borderRadius: "10px"
                                    }}>
                                        <input type="file" id="edit_img" onChange={(e) => {
                                            setProfilePic(e.target.files[0]);
                                            setProfilePicUrl(URL.createObjectURL(e.target.files[0]));
                                            console.log(URL.createObjectURL(e.target.files[0]));
                                        }}/>
                                        <span className="avatar-edit">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                         fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                         stroke-linejoin="round"
                         className="feather feather-edit-2 avatar-uploader-icon shadow-soft">
                      <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                    </svg>
                  </span>
                                    </label>
                                    <div className="col-8 create-form-upper-sec">
                                        <div className="col-sm-12">
                                            <input type="text" className="form-control w-100" id="name"
                                                   placeholder="Full Name" onChange={(e) => setName(e.target.value)}
                                                   required={true}/>
                                            <div style={{position: "relative", bottom: "35px", left: "15px", width: "10px"}}>
                                                <svg width="20" height="22" viewBox="0 0 20 22" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M13.71 11.71C14.6904 10.9387 15.406 9.88092 15.7572 8.68394C16.1085 7.48697 16.0779 6.21027 15.6698 5.03147C15.2617 3.85267 14.4963 2.83039 13.4801 2.10686C12.4639 1.38332 11.2474 0.994507 10 0.994507C8.75255 0.994507 7.53611 1.38332 6.51993 2.10686C5.50374 2.83039 4.73834 3.85267 4.33021 5.03147C3.92208 6.21027 3.89151 7.48697 4.24276 8.68394C4.59401 9.88092 5.3096 10.9387 6.29 11.71C4.61007 12.383 3.14428 13.4994 2.04889 14.9399C0.953495 16.3805 0.26956 18.0913 0.0699967 19.89C0.0555513 20.0213 0.0671132 20.1542 0.104022 20.2811C0.140931 20.4079 0.202464 20.5263 0.285108 20.6293C0.452016 20.8375 0.69478 20.9708 0.959997 21C1.22521 21.0292 1.49116 20.9518 1.69932 20.7849C1.90749 20.618 2.04082 20.3752 2.07 20.11C2.28958 18.1552 3.22168 16.3498 4.68822 15.0388C6.15475 13.7278 8.0529 13.003 10.02 13.003C11.9871 13.003 13.8852 13.7278 15.3518 15.0388C16.8183 16.3498 17.7504 18.1552 17.97 20.11C17.9972 20.3557 18.1144 20.5827 18.2991 20.747C18.4838 20.9114 18.7228 21.0015 18.97 21H19.08C19.3421 20.9698 19.5817 20.8373 19.7466 20.6313C19.9114 20.4252 19.9881 20.1624 19.96 19.9C19.7595 18.0962 19.0719 16.381 17.9708 14.9382C16.8698 13.4954 15.3969 12.3795 13.71 11.71ZM10 11C9.20887 11 8.43551 10.7654 7.77772 10.3259C7.11992 9.88636 6.60723 9.26164 6.30448 8.53074C6.00173 7.79983 5.92251 6.99557 6.07686 6.21964C6.2312 5.44372 6.61216 4.73099 7.17157 4.17158C7.73098 3.61217 8.44371 3.2312 9.21964 3.07686C9.99556 2.92252 10.7998 3.00173 11.5307 3.30448C12.2616 3.60724 12.8863 4.11993 13.3259 4.77772C13.7654 5.43552 14 6.20888 14 7C14 8.06087 13.5786 9.07828 12.8284 9.82843C12.0783 10.5786 11.0609 11 10 11Z"
                                                        fill="#9079E9"/>
                                                </svg>
                                            </div>

                                        </div>

                                        <div className="col-sm-12">
                                            <input type="email" className="form-control w-100" id="name" placeholder="Email"
                                                   onChange={(e) => setEmail(e.target.value)} required={true}/>
                                            <div style={{position: "relative", bottom: "35px", left: "15px", width: "10px"}}>
                                                <svg width="18" height="16" viewBox="0 0 18 16" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M13.1162 0.5C14.2337 0.5 15.3087 0.941667 16.0995 1.73417C16.8912 2.525 17.3337 3.59167 17.3337 4.70833V11.2917C17.3337 13.6167 15.442 15.5 13.1162 15.5H4.88366C2.55783 15.5 0.666992 13.6167 0.666992 11.2917V4.70833C0.666992 2.38333 2.54949 0.5 4.88366 0.5H13.1162ZM14.442 5.95L14.5087 5.88333C14.7078 5.64167 14.7078 5.29167 14.4995 5.05C14.3837 4.92583 14.2245 4.85 14.0587 4.83333C13.8837 4.82417 13.717 4.88333 13.5912 5L9.83366 8C9.35033 8.40083 8.65783 8.40083 8.16699 8L4.41699 5C4.15783 4.80833 3.79949 4.83333 3.58366 5.05833C3.35866 5.28333 3.33366 5.64167 3.52449 5.89167L3.63366 6L7.42533 8.95833C7.89199 9.325 8.45783 9.525 9.05033 9.525C9.64116 9.525 10.217 9.325 10.6828 8.95833L14.442 5.95Z"
                                                          fill="#9079E9"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{position: "relative"}} className="row form-group">

                            <div className="col-12">
                                <input type="tel" className="form-control w-100" id="phone" placeholder="Phone"
                                       onChange={(e) => setPhoneNumber(e.target.value)}
                                />
                                <div style={{position: "absolute", top: "22%", left: "30px"}}>
                                    <svg width="14" height="20" viewBox="0 0 14 20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M7.71 14.29L7.56 14.17C7.50426 14.1322 7.44372 14.1019 7.38 14.08L7.2 14C7.03782 13.9661 6.86976 13.973 6.71092 14.0201C6.55208 14.0673 6.40744 14.1531 6.29 14.27C6.20167 14.3672 6.13064 14.4788 6.08 14.6C6.00432 14.7822 5.98423 14.9827 6.02226 15.1763C6.06028 15.3699 6.15472 15.5479 6.2937 15.688C6.43267 15.828 6.60996 15.9238 6.80326 15.9633C6.99656 16.0028 7.19722 15.9843 7.38 15.91C7.4995 15.852 7.61052 15.778 7.71 15.69C7.84876 15.5494 7.94275 15.3708 7.98013 15.1768C8.0175 14.9828 7.99658 14.7821 7.92 14.6C7.87014 14.4844 7.79889 14.3792 7.71 14.29ZM11 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H11C11.7956 20 12.5587 19.6839 13.1213 19.1213C13.6839 18.5587 14 17.7956 14 17V3C14 2.20435 13.6839 1.44129 13.1213 0.87868C12.5587 0.316071 11.7956 0 11 0ZM12 17C12 17.2652 11.8946 17.5196 11.7071 17.7071C11.5196 17.8946 11.2652 18 11 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H11C11.2652 2 11.5196 2.10536 11.7071 2.29289C11.8946 2.48043 12 2.73478 12 3V17Z"
                                            fill="#9079E9"/>
                                    </svg>

                                </div>
                            </div>
                        </div>
                        <div style={{position: "relative"}} className="row form-group">

                            <div className="col-12">
                                <input type="password" className="form-control w-100" id="password" placeholder="Password" value={password}
                                       onChange={(e) => setPassword(e.target.value)} required={true}
                                />
                                <div style={{position: "absolute", top: "22%", left: "30px"}}>
                                    <svg width="16" height="18" viewBox="0 0 16 18" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M12.6021 5.16336V6.44114C14.0374 6.88915 15.0832 8.18847 15.0832 9.74036V13.8544C15.0832 15.7757 13.4904 17.3334 11.5267 17.3334H4.47384C2.5093 17.3334 0.916504 15.7757 0.916504 13.8544V9.74036C0.916504 8.18847 1.96313 6.88915 3.39758 6.44114V5.16336C3.40604 2.67901 5.46373 0.666687 7.98714 0.666687C10.5444 0.666687 12.6021 2.67901 12.6021 5.16336ZM8.00407 2.11589C9.72304 2.11589 11.1202 3.48228 11.1202 5.16336V6.26144H4.87944V5.1468C4.88791 3.474 6.2851 2.11589 8.00407 2.11589ZM8.74077 12.7125C8.74077 13.1182 8.41053 13.4412 7.9956 13.4412C7.58915 13.4412 7.2589 13.1182 7.2589 12.7125V10.874C7.2589 10.4766 7.58915 10.1536 7.9956 10.1536C8.41053 10.1536 8.74077 10.4766 8.74077 10.874V12.7125Z"
                                              fill="#9079E9"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div style={{position: "relative"}} className="row form-group">

                            <div className="col-12">
                                <input type="password" className="form-control w-100" id="cf-password" placeholder="Confirm Password" value={cfPassword}
                                       onChange={(e) => setCfPassword(e.target.value)} required={true}
                                />
                                <div style={{position: "absolute", top: "22%", left: "30px"}}>
                                    <svg width="16" height="18" viewBox="0 0 16 18" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M12.6021 5.16336V6.44114C14.0374 6.88915 15.0832 8.18847 15.0832 9.74036V13.8544C15.0832 15.7757 13.4904 17.3334 11.5267 17.3334H4.47384C2.5093 17.3334 0.916504 15.7757 0.916504 13.8544V9.74036C0.916504 8.18847 1.96313 6.88915 3.39758 6.44114V5.16336C3.40604 2.67901 5.46373 0.666687 7.98714 0.666687C10.5444 0.666687 12.6021 2.67901 12.6021 5.16336ZM8.00407 2.11589C9.72304 2.11589 11.1202 3.48228 11.1202 5.16336V6.26144H4.87944V5.1468C4.88791 3.474 6.2851 2.11589 8.00407 2.11589ZM8.74077 12.7125C8.74077 13.1182 8.41053 13.4412 7.9956 13.4412C7.58915 13.4412 7.2589 13.1182 7.2589 12.7125V10.874C7.2589 10.4766 7.58915 10.1536 7.9956 10.1536C8.41053 10.1536 8.74077 10.4766 8.74077 10.874V12.7125Z"
                                              fill="#9079E9"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            {params.type === 'manager' ? <div className="mb-3">

                                <Select
                                    name="venues"
                                    className="select w-100"
                                    isMulti={true}
                                    options={venueOptions}
                                    onChange={(e) => {
                                        let _venues = [];
                                        for (let venue of e) {
                                            _venues.push(venue.value);
                                        }
                                        setVenues(_venues);
                                    }}
                                />
                            </div> : params.type === 'valet' ? <div className="mb-3">

                                <Select
                                    name="venues"
                                    className="select w-100"
                                    options={venueOptions}
                                    onChange={(e) => {
                                        setVenues([e.value]);
                                    }}
                                />
                            </div> : <div className="mb-3">

                                <Select
                                    name="permissions"
                                    className="select w-100"
                                    isMulti={true}
                                    options={permissionOptions}
                                    onChange={(e) => {
                                        let _permissions = [];
                                        for (let permission of e) {
                                            _permissions.push(permission.value);
                                        }
                                        setPermissions(_permissions);
                                    }}
                                />

                            </div>}
                        </div>

                        <div className="d-flex justify-content-center">
                            <Button loading={loading} type="submit" className="btn btn-primary me-2 w-75" disabled={loading}><i
                                className="fa fa-check-circle me-2" />Submit</Button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </>);
}

export default AddAdminStaff;
