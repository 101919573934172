import React, {useEffect, useState} from 'react';
import ParkingCard from "../../components/parking/ParkingCard";
import {useLocation, useNavigate, useParams} from "react-router";
import {useSelector} from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ParkingDetailCard from "../../components/parking/ParkingDetailCard";
import Modal from 'react-bootstrap/Modal';

const ParkingListCard = () => {
    const user = useSelector((state) => state.auth);
    const allParking = useSelector((state) => state.parking.parking);
    const allStaff = useSelector((state) => state.staff.admins);
    const allVenue = useSelector((state) => state.venue.venues);
    const [parking, setParking] = useState([]);
    const [showParkingDetail, setShowParkingDetail] = useState(false);
    const [currentParking, setCurrentParking] = useState("");
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1700,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    useEffect(() => {
        let _parking = [];
        let venue;
        let valet;
        allParking.forEach((p) => {
            if (user.type === 'Manager' && user.venues.includes(p.venue.id) || user.type !== 'Manager') {
                venue = allVenue.find((v) => v._id === p.venue.id);
                valet = allStaff.find((s) => s._id === p.valet);
                let parking = { ...p };
                parking.venueDetails = venue;
                parking.valetDetails = valet;
                _parking.push(parking);
            }

        });
        setParking(_parking);
    }, [allParking])

    return (
        <>
            <div className="">
                {allVenue.filter((v) => v.status === "Active" && !v.deleted && (user.type === 'Manager' && user.venues.includes(v._id) || user.type !== 'Manager')).map(venue => (
                    (parking.filter((p) => p.status === "Checked-In" && p.venue.id === venue._id).length > 0 && <div className="mb-5 mt-5">
                        <h3 className="text-center mb-5">{venue.name}</h3>
                        <h5>Checked-In</h5>
                        <Slider {...settings}>
                            {parking.filter((p) => p.status === "Checked-In" && p.venue.id === venue._id).map(p => (
                                <ParkingCard parking={p} onClick={() => {
                                    setCurrentParking(p.ticket);
                                    setShowParkingDetail(true);
                                }} />
                            ))}
                        </Slider>
                    </div>)
                ))}

            </div>

            <Modal
                show={showParkingDetail}
                backdrop="static"
                onHide={() => {
                    setShowParkingDetail(false);
                }}
                keyboard={true}
            >
                {currentParking && <ParkingDetailCard ticket={currentParking} onClose={() => {
                    setShowParkingDetail(false);
                    setCurrentParking(null);
                }} />}
            </Modal>
        </>
    );
}

export default ParkingListCard;
