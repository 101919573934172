import io from "socket.io-client";
import {
    slotAddSuccess, slotDeleteSuccess, slotEditSuccess,
    venueAddSuccess, venueDeleteSuccess, venueEditSuccess,
    venueTypeAddSuccess,
    venueTypeDeleteSuccess,
    venueTypeEditSuccess
} from "../venue/reducer";
import store from "../index";
import {addStaffSuccess, staffDeleteSuccess, staffEditSuccess} from "../staff/reducer";
import {parkingAddSuccess, parkingEditSuccess, vehicleRequested, vehicleRequestUpdated} from "../parking/reducer";
import config from "../../Config";
import {useSelector} from "react-redux";
import {addNotificationSuccess} from "../notification/reducer";

export const socketSync = (user) => (dispatch) => {
    const socket = io(config.socketBaseUrl);


    socket.on('connect', () => {
        console.log("CONNECTED");
    });

    socket.on('disconnect', () => {
        console.log("DISCONNECTED");
    });

    socket.on('venue', (arg) => {
        store.dispatch(syncVenue(arg));
    });

    socket.on('staff', (arg) => {
        store.dispatch(syncStaff(arg));
    });

    socket.on('parking', (arg) => {
        store.dispatch(syncParking(arg));
    });

    socket.on('notification', (arg) => {
        store.dispatch(syncNotification(user, arg));
    });
}

export const syncNotification = (user, body) => (dispatch) => {
    if (body.admin === user.id) {
        console.log(body);
        dispatch({
            type: addNotificationSuccess,
            payload: body,
        });
    }
}

export const syncVenue = (body) => (dispatch) => {
    switch (body.type) {
        case 'addVenueType':
            dispatch({
                type: venueTypeAddSuccess,
                payload: body,
            });
            break;
        case 'editVenueType':
            dispatch({
                type: venueTypeEditSuccess,
                payload: body,
            });
            break;
        case 'deleteVenueType':
            dispatch({
                type: venueTypeDeleteSuccess,
                payload: body,
            });
            break;
        case 'addVenue':
            dispatch({
                type: venueAddSuccess,
                payload: body,
            });
            break;
        case 'addSlot':
            dispatch({
                type: slotAddSuccess,
                payload: body,
            });
            break;
        case 'editVenue':
            dispatch({
                type: venueEditSuccess,
                payload: body,
            });
            break;
        case 'editSlot':
            dispatch({
                type: slotEditSuccess,
                payload: body,
            });
            break;
        case 'deleteVenue':
            dispatch({
                type: venueDeleteSuccess,
                payload: body,
            });
            break;
        case 'deleteSlot':
            dispatch({
                type: slotDeleteSuccess,
                payload: body,
            });
            break;
    }
}


export const syncStaff = (body) => (dispatch) => {
    switch (body.type) {
        case 'addStaff':
            dispatch({
                type: addStaffSuccess,
                payload: body,
            });
            break;
        case 'deleteStaff':
            dispatch({
                type: staffDeleteSuccess,
                payload: body,
            });
            break;
        case 'editStaff':
            dispatch({
                type: staffEditSuccess,
                payload: body,
            });
            break;
    }
}

export const syncParking = (body) => (dispatch) => {
    switch (body.type) {
        case 'addParking':
            dispatch({
                type: parkingAddSuccess,
                payload: body,
            });
            break;
        case 'editParking':
            dispatch({
                type: parkingEditSuccess,
                payload: body,
            });
            break;
        case 'vehicleRequested':
            dispatch({
                type: vehicleRequested,
                payload: body,
            });
            break;
        case 'vehicleRequestUpdated':
            dispatch({
                type: vehicleRequestUpdated,
                payload: body,
            });
            break;
    }
}
