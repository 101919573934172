import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const initialState = { config: [] }

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        addConfigSuccess(state, action) {
            if (action.payload.data) {
                state.config.push(action.payload.data);
                toast.info(action.payload.message, {});
            }
        },
        addConfigFailed(state, action) {
            toast.warning(action.payload.message, {});
        },
        configLoaded(state, action) {
            state.config.push(...action.payload.data);
        },
        configDeleteSuccess(state, action) {
            if (action.payload.data) {
                state.config = state.config.filter(config => config._id !== action.payload.data._id);
                toast.info(action.payload.message, {});
            }
        },
        configDeleteFailed(state, action) {
            toast.warning(action.payload.message, {});
        },
        configEditSuccess(state, action) {
            if (action.payload.data) {
                let index = state.config.findIndex(config => config._id === action.payload.data._id);
                if (index >= 0) {
                    state.config[index] = action.payload.data;
                    toast.info(action.payload.message, {});
                }
            }
        },
        configEditFailed(state, action) {
            toast.warning(action.payload.message, {});
        },
    },
})

export const { addConfigSuccess, addConfigFailed, configLoaded, configDeleteSuccess, configDeleteFailed, configEditSuccess, configEditFailed } = configSlice.actions
export default configSlice.reducer
