import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {useSelector} from "react-redux";
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net';
import {useLocation, useNavigate, useParams} from "react-router";
import store from "../../store";
import moment from "moment";

const ManageParking = () => {
    const user = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const allParking = useSelector((state) => state.parking.parking);
    const allStaff = useSelector((state) => state.staff.admins);
    const allVenue = useSelector((state) => state.venue.venues);
    const [parking, setParking] = useState([]);

    useEffect(() => {
        try {
            $('#table').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        $('#table').DataTable({
            data: parking,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        if (row[4] === 'Checked-In') {
                            return `<p class="badge bg-primary">${data}</p>`;
                        } else if (row[4] === 'Parked') {
                            return `<p class="badge bg-success">${data}</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 0
                },
                {

                    "render": function (data, type, row) {
                        if (data === 'Checked-In') {
                            return `<p class="badge bg-primary">Checked In</p>`;
                        } else if (data === 'Parked') {
                            return `<p class="badge bg-success">Parked</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 4
                },
                {
                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary"><i class="fa fa-pen me-2"></i>Details</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            console.log('CLICKED');
                            navigate(`${location.pathname}/${cellData}`);
                        })
                    },
                    "targets": 7
                },
            ],
            order: []
        });
    }, [parking]);


    useEffect(() => {
        let _parking = [];
        let venue;
        let valet;
        allParking.forEach((p) => {
            if (user.type === 'Manager' && user.venues.includes(p.venue.id) || user.type !== 'Manager') {
                venue = allVenue.find((v) => v._id === p.venue.id);
                valet = allStaff.find((s) => s._id === p.valet);

                try {
                    if (p.status === 'Checked-In') {
                        _parking.push([p.ticket, p.car.licensePlate, moment(p.timings.arrival || "").format('MMMM Do YYYY, h:mm:ss a'), "", p.status, venue.name, valet.name, p._id]);
                    } else if (p.status === 'Parked'){
                        _parking.push([p.ticket, p.car.licensePlate, moment(p.timings.arrival || "").format('MMMM Do YYYY, h:mm:ss a'), moment(p.timings.parked || "").format('MMMM Do YYYY, h:mm:ss a'), p.status, venue.name, valet.name, p._id]);
                    }
                } catch (e) {
                    console.log("ERR");
                    console.log(p);
                }
            }

        });
        setParking(_parking);
    }, [allParking])


    return (
        <div>
            <div class="card mt-3">
                <div className="card-header">
                    <h5 className="card-title">Parking Management</h5>
                </div>
                <div class="card-body">
                    <div className='table-responsive'>
                        <table
                            id="table"
                            className='table datatable'
                        >
                            <thead>
                            <tr>
                                <th>Ticket</th>
                                <th>License Plate</th>
                                <th>Arrival Time</th>
                                <th>Parked Time</th>
                                <th>Status</th>
                                <th>Venue</th>
                                <th>Valet</th>
                                <th>Details</th>
                            </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default ManageParking;
