import {
    checkInSuccess, operationFailed, parkingLoadSuccess, requestLoadSuccess, vehicleRequestUpdated
} from "./reducer";
import axios, {AxiosError} from "axios";
import baseConfig from "../../Config";

export const getAllParking = () => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        let page = 1;
        let limit = 100;
        while (page !== 0) {
            try {
                let res = await axios.get(
                    `${baseConfig.apiBaseUrl}/admin/parking/parking/all?page=${page}&limit=${limit}`,
                    config
                );
                console.log(res.data);
                if (res.data['status']) {
                    if (res.data.data.length !== 0) {
                        dispatch({
                            type: parkingLoadSuccess,
                            payload: res.data,
                        });
                        page++;
                    } else {
                        page = 0;
                    }
                }
            } catch (err) {
                if (err.response) {
                    dispatch({
                        type: operationFailed,
                        payload: err.response.data,
                    });
                }
            }
        }

    }
}

export const getAllRequest = () => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        let page = 1;
        let limit = 100;
        while (page !== 0) {
            try {
                let res = await axios.get(
                    `${baseConfig.apiBaseUrl}/admin/parking/request/all?page=${page}&limit=${limit}`,
                    config
                );
                if (res.data['status']) {
                    if (res.data.data.length !== 0) {
                        dispatch({
                            type: requestLoadSuccess,
                            payload: res.data,
                        });
                        page++;
                    } else {
                        page = 0;
                    }
                }
            } catch (err) {
                if (err.response) {
                    dispatch({
                        type: operationFailed,
                        payload: err.response.data,
                    });
                }
                page = 0;
            }
        }

    }
}

export const assignValetToRequest = (requestId, valet) => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        try {
            let res = await axios.patch(
                `${baseConfig.apiBaseUrl}/admin/parking/request/${requestId}/assign-valet`,
                {valet},
                config
            );
            if (res.data['status']) {

            }
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: operationFailed,
                    payload: err.response.data,
                });
            }
        }

    }
}
