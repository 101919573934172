import axios from "axios";
import baseConfig from "../../Config";
import {addNotificationFailed, notificationLoaded, resetNotification} from "./reducer";
import {addConfigFailed, configEditSuccess} from "../config/reducer";

export const getAllNotifications = () => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        dispatch({
            type: resetNotification,
            payload: null,
        });

        let page = 1;
        let limit = 100;
        while (page !== 0) {
            try {
                let res = await axios.get(
                    `${baseConfig.apiBaseUrl}/admin/notification/all?page=${page}&limit=${limit}`,
                    config
                );
                console.log(res.data);
                if (res.data['status']) {
                    if (res.data.data.length !== 0) {
                        dispatch({
                            type: notificationLoaded,
                            payload: res.data,
                        });
                        page++;
                    } else {
                        page = 0;
                    }
                }
            } catch (err) {
                if (err.response) {
                    dispatch({
                        type: addNotificationFailed,
                        payload: err.response.data,
                    });
                }
            }
        }

    }
}


export const readAllNotification = async () =>  {
    let token = localStorage.getItem("token");
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        };
        try {
            let res = await axios.patch(
                `${baseConfig.apiBaseUrl}/admin/notification/read-all`,
                {},
                config
            );

        } catch (err) {
            console.log(err)
        }
    }
};
